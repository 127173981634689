import { DragDropModule } from '@angular/cdk/drag-drop';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BlockUIModule } from 'ng-block-ui';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { ToastrModule } from 'ngx-toastr';
import { AccordionComponent } from './accordion/accordion.component';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArabicDatePipe } from './arabic-date.pipe';
import { BookingsComponent } from './bookings/bookings.component';
import { CancelPolicyDialogComponent } from './cancel-policy-dialog/cancel-policy-dialog.component';
import { CancellationPolicyComponent } from './cancellation-policy/cancellation-policy.component';
import { CardComponent } from './card/card.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryItemComponent } from './category-item/category-item.component';
import { ChaletsOptionsComponent } from './chalets-options/chalets-options.component';
import { ChatItemComponent } from './chat-item/chat-item.component';
import { ChatComponent } from './chat/chat.component';
import { CheckoutDialogComponent } from './checkout-dialog/checkout-dialog.component';
import { CloseDestinationsComponent } from './close-destinations/close-destinations.component';
import { CreateRoomComponent } from './create-room/create-room.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DealsComponent } from './deals/deals.component';
import { DownloadComponent } from './download/download.component';
import { FaqComponent } from './faq/faq.component';
import { FilterComponent } from './filter/filter.component';
import { FinalReviewReservationComponent } from './final-review-reservation/final-review-reservation.component';
import { FooterComponent } from './footer/footer.component';
import { OnlyNumbersDirective } from './global-directives/only-numbers.directive';
import { HeaderCarouselComponent } from './header-carousel/header-carousel.component';
import { HomeComponent } from './home/home.component';
import { HotelDetailsComponent } from './hotel-details/hotel-details.component';
import { HotelRateComponent } from './hotel-rate/hotel-rate.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { ImagesViewerComponent } from './images-viewer/images-viewer.component';
import { MightLikeComponent } from './might-like/might-like.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NewPropertyComponent } from './new-property/new-property.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { OrderStatisticsComponent } from './order-statistics/order-statistics.component';
import { OrdersTableComponent } from './orders-table/orders-table.component';
import { OrdersComponent } from './orders/orders.component';
import { PaymentHistoryListComponent } from './payment-history-list/payment-history-list.component';
import { PaymentsListManageComponent } from './payments-list-manage/payments-list-manage.component';
import { PaymentsListComponent } from './payments-list/payments-list.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { PictureViewerComponent } from './picture-viewer/picture-viewer.component';
import { CancelNameArPipe } from './Pipes/cancel-name-ar.pipe';
import { DateTimePipe } from './Pipes/date-time.pipe';
import { PopUpComponent } from './pop-up/pop-up.component';
import { PopupComponent } from './popup/popup.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProfileComponent } from './profile/profile.component';
import { RatingManageComponent } from './rating-manage/rating-manage.component';
import { RecomendedComponent } from './recomended/recomended.component';
import { ReservationDetailsComponent } from './reservation-details/reservation-details.component';
import { ReservationInfoComponent } from './reservation-info/reservation-info.component';
import { ReservationManagementTableComponent } from './reservation-management-table/reservation-management-table.component';
import { ReservationManagementComponent } from './reservation-management/reservation-management.component';
import { ReservationOrderComponent } from './reservation-order/reservation-order.component';
import { ReservationComponent } from './reservation/reservation.component';
import { ReservationsStepperComponent } from './reservations-stepper/reservations-stepper.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { ReviewReservationPaymentComponent } from './review-reservation-payment/review-reservation-payment.component';
import { ReviewReservationsCardComponent } from './review-reservations-card/review-reservations-card.component';
import { ReviewReservationsDetailsComponent } from './review-reservations-details/review-reservations-details.component';
import { ReviewReservationsFormsComponent } from './review-reservations-forms/review-reservations-forms.component';
import { ReviewReservationsComponent } from './review-reservations/review-reservations.component';
import { RoomOptionsComponent } from './room-options/room-options.component';
import { RoomsListComponent } from './rooms-list/rooms-list.component';
import { RoomsManagmentComponent } from './rooms-managment/rooms-managment.component';
import { SearchDetails } from './search-details/search-details.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchcardsComponent } from './searchcards/searchcards.component';
import { initApp } from './services/app-init-service/app-init';
import { AuthenticationService } from './services/auth/authentication.service';
import { DataContextService } from './services/global-context/data-context.service';
import { SigninComponent } from './signin/signin.component';
import { SignoutComponent } from './signout/signout.component';
import { SummaryOverviewComponent } from './summary-overview/summary-overview.component';
import { TechnicalSupportComponent } from './technical-support/technical-support.component';
import { TosComponent } from './tos/tos.component';
import { UsagePolicyComponent } from './usage-policy/usage-policy.component';
import { UserReviewsComponent } from './user-reviews/user-reviews.component';
import { WhishlistComponent } from './whishlist/whishlist.component';

// defineLocale('ar', arLocale);
const googleLoginOptions = {
  scope: 'profile email',
};
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    SearchDetails,
    DatepickerComponent,
    ReservationComponent,
    HotelDetailsComponent,
    ChaletsOptionsComponent,
    ReservationInfoComponent,
    UserReviewsComponent,
    RoomOptionsComponent,
    ImageGalleryComponent,
    AccordionComponent,
    HotelRateComponent,
    FilterComponent,
    CardComponent,
    AppComponent,
    CategoriesComponent,
    CategoryItemComponent,
    DealsComponent,
    RecomendedComponent,
    CloseDestinationsComponent,
    MightLikeComponent,
    DownloadComponent,
    HomeComponent,
    PhoneInputComponent,
    FooterComponent,
    NavbarComponent,
    SearchcardsComponent,
    HeaderCarouselComponent,
    SigninComponent,
    SearchResultsComponent,
    NavigationComponent,
    ReservationManagementComponent,
    ArabicDatePipe,
    NotificationsComponent,
    WhishlistComponent,
    FaqComponent,
    PopupComponent,
    ImagesViewerComponent,
    ReservationDetailsComponent,
    ReservationOrderComponent,

    ProfileComponent,
    SummaryOverviewComponent,
    ReservationManagementTableComponent,
    ReservationsComponent,
    OrdersTableComponent,
    OrdersComponent,
    OrderStatisticsComponent,
    AllOrdersComponent,
    NewPropertyComponent,

    TechnicalSupportComponent,
    CreateRoomComponent,
    ReviewReservationsComponent,
    ReviewReservationsCardComponent,
    ReviewReservationsDetailsComponent,
    ReviewReservationsFormsComponent,
    FinalReviewReservationComponent,
    ReviewReservationPaymentComponent,
    BookingsComponent,
    ReservationsStepperComponent,
    PictureViewerComponent,
    RoomsListComponent,
    RoomsManagmentComponent,
    OnlyNumbersDirective,
    CheckoutDialogComponent,
    PrivacyPolicyComponent,
    TosComponent,
    UsagePolicyComponent,
    CancellationPolicyComponent,
    NumbersOnlyDirective,
    RatingManageComponent,
    CancelPolicyDialogComponent,
    PaymentsListComponent,
    PaymentsListManageComponent,
    SignoutComponent,
    PaymentHistoryListComponent,
    PopUpComponent,
    CancelNameArPipe,
    ChatComponent,
    ChatItemComponent,
    DateTimePipe,
  ],
  imports: [
    // SocialLoginModule,
    MatMenuModule,
    MatRadioModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatSlideToggleModule,
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    MatGridListModule,
    RouterModule,
    NgxMaterialTimepickerModule.setOpts('ar-AE', 'arab'),

    CarouselModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    DragDropModule,
    NgxDropzoneModule,
    MatChipsModule,
    DragDropModule,
    BsDropdownModule.forRoot(),
    GalleryModule,
    LightboxModule,
    ToastrModule.forRoot(),
    NgxIntlTelInputModule,
    BlockUIModule.forRoot(),
    AutocompleteLibModule,
    HttpClientModule,
    NgxStarRatingModule,
    GoogleMapsModule,
    MatTooltipModule,
  ],

  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AuthenticationService, DataContextService, Router],
      multi: true,
    },
    // {
    //   provide:HTTP_INTERCEPTORS,useClass:AuthInterceptorInterceptor,multi:true
    // }
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider('162526198800-kgcnl7c8tkbmko1amfi8ebr01hnuoo9u.apps.googleusercontent.com', googleLoginOptions)
    //       }
    //     ]
    //   } as SocialAuthServiceConfig
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
