<div class="cont">
   <div>
    <div class="date-cont">
        <p class="date-title">{{'booking_date'|translate}}</p>
        <p class="date-info">{{dateOfReservation}}</p>
    </div>
    <div class="room-cont"  *ngFor="let room of rooms">
        <p class="img-title">{{'propertyDetails'|translate}}</p>
        <div class="orientation">
            <div class="lower-img-container">
            <!-- <div class=" card-image">
                <img [src]="room.images[0]" alt="" class="img-inner">
            </div> -->
            </div>
            <div class="room-info">
                <div style="display: grid;gap: 40px; grid-template-columns: repeat(2,1fr); margin-bottom: 20px;">
                    <div class="property-detail">
                      <i class="fas fa-door-open"></i>
                      <p>{{'rooms'|translate}}: {{ reservationInfo.Property.rooms }}</p>
                    </div>
                    <div class="property-detail">
                      <i class="fas fa-user-friends"></i>
                      <p>{{'guests'|translate}}: {{ reservationInfo.Property.guests }}</p>
                    </div>
                    <div class="property-detail">
                      <i class="fas fa-bed"></i>
                      <p>{{'beds'|translate}}: {{reservationInfo.Property.beds }}</p>
                    </div>
                    <div class="property-detail">
                      <i class="fas fa-bath"></i>
                      <p>{{'bathrooms'|translate}}: {{ reservationInfo.Property.bathrooms }}</p>
                    </div>
                    <div class="property-detail">
                      <i class="fas fa-ruler-combined"></i>
                      <p>{{'totalSpace'|translate}}: {{ reservationInfo.Property.totalSpace}} {{'meters'|translate}}</p>
                    </div>
                  </div>
            </div>
        </div>
    </div>
    <div class="name-info">
        <p class="title"> {{'guest_name'|translate}}</p>
        <p class="info">{{reservationInfo.firstName + ' ' + reservationInfo.lastName}}</p>
        <p class="title">{{'mobile_number'|translate}}</p>
        <p class="info" dir="ltr">{{reservationInfo.mobile}}</p>
        <p class="title">{{'destination'|translate}}</p>
        <p class="info">{{!isFlipped?property.nameAr:property.nameEn + ' , ' + property.city}}</p>
        <div class="orientaiton">
            <div>
                <p class="title">{{'check_in'|translate}}</p>
                <p class="info">{{getDateStartEnd(reservationInfo.startDate)}}</p>
            </div>
            <div>
                <p class="title">{{'check_out'|translate}}</p>
                <p class="info">{{getDateStartEnd(reservationInfo.endDate)}} </p>
            </div>
        </div>
        <p class="title">{{'guest'|translate}}</p>
        <p class="info">{{reservationInfo.adults}} {{'adults'|translate}}, {{reservationInfo.childerns}} {{'children'|translate}}</p>
    </div>
    <p class="title map-title" style="cursor: pointer; "  (click)="openMapInNewTab()">{{'map_directions'|translate}}</p>
    
    <div  (click)="openMapInNewTab()"  >

        <google-map *ngIf="property.mapLat && property.mapLat"  height="400px" width="100%" [options]="options">
            <map-advanced-marker   [position]="markerPosition"></map-advanced-marker>
        </google-map>
    </div>
   </div>
   <div class="payment-info ">

    <p class="title">{{'payment_method'|translate}}</p>
    <div *ngIf="reservationInfo.paymentStatus == 'pending'" class="complete-payment">
        <button class="custom-button" (click)="completePayment()">{{'complete_payment_and_confirm_booking'|translate}}</button>
      </div>  
    <p class="info padding-below">{{ (reservationInfo.paymentMethod || '').split(' ')[0] }}</p>
    <p class="title">{{'price'|translate}}</p>
    <p class="info padding-below">{{ reservationInfo.price }}</p> 

    <p *ngIf="reservationInfo.price !== reservationInfo.priceAfterDiscount" class="title">{{'priceAfter'|translate}}</p>
    <p *ngIf="reservationInfo.price !== reservationInfo.priceAfterDiscount" class="info padding-below">{{ reservationInfo.priceAfterDiscount }}</p>
    <!-- <p class="title">{{'vat'|translate}}</p>
    <p class="info padding-below">{{ reservationInfo.vat }}</p>
    <p class="title">{{'price_after_vat'|translate}}</p>
    <p class="info padding-below">{{ reservationInfo.priceAfterVat }}</p>
    <p class="title">{{'net_price'|translate}}</p>
    <p class="info padding-below">{{ reservationInfo.netPrice }}</p> -->
    <!-- <div>
        <img src="assets/aroovia-icons/frame.svg" alt="barcode">
    </div> -->
    <p class="title">{{'booking_confirmed'|translate}}</p>
    <p class="info">{{reservationInfo.id}}</p>
    <p class="title">{{'confirmation_email'|translate}}</p>
    <p class="info">{{reservationInfo.email}}</p>
   </div>

  </div>
