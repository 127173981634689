<div class="container-s">
    <p class="destination-title text-align-start">
        {{'destination'|translate}}
    </p>
    <p class="destination text-align-start">
      {{ !isFlipped? reservationDetails.property.nameAr:reservationDetails.property.nameEn + ' , ' + reservationDetails.property.city }}
    </p>
    <span class="location-span">
        <img src="../../assets/icons/gray-pin.svg" alt="">
        <p class="location-country">
           {{reservationDetails.property.city}}
        </p>
    </span>
    <div class="image-area">
        <div class="image-container">
            <img [src]="reservationDetails.property.mainImage" alt="" class="card-image">
        </div>
        <div class="info">
            <div class="section-one">
                <p class="date-title text-align-start">
                    {{'booking_date'|translate}}
                </p>
                <p class="date">
                  {{   ('from'|translate)+ ' ' + dateFormate(reservationDetails.startDate)  +'   , '+  ('to'|translate)+'  ' + dateFormate(reservationDetails.endDate )}}
                    <!-- من 24 يناير 2023 حتى 2 يناير 2024 -->
                </p>
            </div>
            <div class="section-two">
                <p class="date-title text-align-start">
                    {{'guest'|translate}}
                </p>
                <p class="guests">
                    {{reservationDetails.adults}} {{'adults'|translate}}، {{reservationDetails.children}} {{'children'|translate}}

                </p>
            </div>
        </div>
        <div class="lower-info">
            <!-- <p class="info-title text-align-start">
                {{'selected_room'|translate}}
            </p> -->
        <div class="orientation my-4" *ngFor="let room of roomsChoosen">
            <!-- <div class="lower-img-container" *ngIf="room.room.images.length">
                <img [src]="room.room.images[0]" alt="" class="card-image">
            </div> -->
            <div class="room-info">
                <!-- <p class="room-info-title">
                  {{room.room.name}}
                </p> -->
                <!-- <span class="details-row">
                    <img src="../../assets/icons/double-bed-icon.svg" alt="">
                    <p class="info-text">{{room.room.bedCount}} {{'king_bed'|translate}}</p>
                </span> -->
                <!-- <span class="details-row">
                    <img src="../../assets/icons/Single Bed.svg" alt="">
                    <p class="info-text">X2 سرير كبير</p>
                </span>
                <span  class="details-row">
                    <img src="../../assets/icons/sofa-bed-s.svg" alt="">
                    <p class="info-text">X1 سرير كبير</p>
                </span> -->
            </div>
        </div>
        </div>
    </div>
</div>
