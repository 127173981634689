import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SearchDTO } from 'app/search-details/search-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { wishlistDTO } from 'app/whishlist/wishlish-dto';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MatDialog } from '@angular/material/dialog';
import { SigninComponent } from 'app/signin/signin.component';
@Component({
  selector: 'app-recomended',
  templateUrl: './recomended.component.html',
  styleUrls: ['./recomended.component.css'],
})
export class RecomendedComponent implements OnChanges, OnInit {
  @Input('wishlist') wislishData: wishlistDTO[] | null = null;
  @Input('mayLike') mayLikeList: SearchDTO[] | null = null;
  @Output('onToggleLike') toggleLike: EventEmitter<any> = new EventEmitter();

  @ViewChild('owlCarousel') owlCarousel: any;
  isFlipped = false;

  // title: string = 'ضيوفنا يفضلون';
  filtertitle: string = 'تصفية';
  @BlockUI('root') rootBlocker!: NgBlockUI;
  customOptions: OwlOptions = {
    // loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1.5,
      },
      940: {
        items: 0,
      },
    },
    nav: true,
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datacontext: DataContextService,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {}

  favorites: Set<number>;
  ngOnInit(): void {
    this.datacontext.favoritesSubject.subscribe((favorites) => {
      this.favorites = favorites;
    });
    if (this.translate.currentLang !== 'en') {
      this.customOptions = {
        ...this.customOptions,
        rtl: true,
      };
    }
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['wislishData'] ) {
    //   if (this.wislishData && this.wislishData?.length > 3) {
    //     this.customOptions = {
    //       loop:true,
    //       mouseDrag: true,
    //       touchDrag: true,
    //       pullDrag: true,
    //       navSpeed: 700,
    //       autoWidth: false,
    //       navText: ['', ''],
    //       rtl:true,
    //       dots:false,
    //        responsive: {
    //         0: {
    //           items: 1.5,
    //         },
    //         940: {
    //           items:4
    //         }
    //       },
    //       nav: true,
    //     }
    //   }else{
    //     this.customOptions = {
    //       // loop: false,
    //       mouseDrag: true,
    //       touchDrag: true,
    //       pullDrag: true,
    //       dots: false,
    //       navSpeed: 700,
    //       navText: ['', ''],
    //       rtl: true,
    //       responsive: {
    //         0: {
    //           items: 1.5,
    //         },
    //         940: {
    //           items: 0,
    //         },
    //       },
    //       nav: true,
    //     };
    //   }
    // }
    // if (changes['mayLikeList']) {
    //   if (this.mayLikeList && this.mayLikeList.length > 3) {
    //     this.customOptions = {
    //       mouseDrag: true,
    //       loop:true,
    //       touchDrag: true,
    //       pullDrag: true,
    //       navSpeed: 700,
    //       autoWidth: false,
    //       navText: ['', ''],
    //       rtl:true,
    //       dots:false,
    //        responsive: {
    //         0: {
    //           items: 1.5,
    //         },
    //         940: {
    //           items:4.5
    //         }
    //       },
    //       nav: true
    //     }
    //   }
    // }
  }

  trackByProperty(index: number, card: wishlistDTO): any {
    return card.Property.id; // or any unique property
  }

  timerId: any;
  onScroll(event: WheelEvent) {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    event.preventDefault();

    this.timerId = setTimeout(() => {
      event.stopPropagation();
      if (event.deltaX < 0) {
        this.owlCarousel.next([150]);
      }
    }, 100);
  }

  goToDetails(id: number) {
    const activatedRoute = this.router.url.includes('/search');
    if (activatedRoute) {
      this.activatedRoute.queryParamMap.subscribe((query: ParamMap) => {
        const searchResult: any = {
          startDate: query.get('startDate'),
          endDate: query.get('endDate'),
          adults: query.get('adults'),
          rooms: query.get('rooms'),
          destination: query.get('destination'),
        };

        localStorage.setItem('searchResult', JSON.stringify(searchResult));
        this.router.navigate(['/details', id]);
      });
    } else {
      this.router.navigate(['/details', id]);
    }
  }

  onLikeProperty(cardProperty: wishlistDTO) {
    if (this.datacontext.userData) {
      // check if the user is authenticated
      this.toggleLike.emit(cardProperty);
    } else {
      this.datacontext.showError('', this.translate.instant('please_register'));
      this.router.navigate(['/signin']).then(() => {
        window.scrollTo(0, 0);
      });
    }
  }

  onMayLike(property: SearchDTO) {
    if (this.datacontext.userData) {
      // check if the user is authenticated
      this.toggleLike.emit(property);
    } else {
      this.datacontext.showError('', this.translate.instant('please_register'));
      this.dialog.open(SigninComponent, {
        width:'450px',
        disableClose:true
      });
      return;
    }
  }
}
