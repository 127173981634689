<div class="container" style="height: 100%;">
    <div class="row" style="height: 100%;">
        <div class="col-sm-12 col-lg-6 p-0" *ngIf="adsDetails.image">
            <img [src]="adsDetails.image" alt="preview">
        </div>
        <div class="col-sm-12 col-lg-6 p-0" *ngIf="!adsDetails.image">
            <img [src]="adsDetails.details.mainImage" alt="preview">
        </div>
        <div class="col-sm-12 col-lg-6">
            <div class="d-flex align-items-center justify-content-center flex-column h-100 gap-3 position-relative">
                <h2>{{adsDetails.title}}</h2>
                <p> {{adsDetails.body}}</p>
                <div class="d-flex align-items-center justify-content-center">
                    <button class="custom-button" (click)="onHandelReservation()">{{'book_now'|translate}}</button>
                </div>
            </div>
            <mat-icon mat-button mat-dialog-close class="close">close</mat-icon>
        </div>
    </div>
</div>