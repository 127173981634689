<div class="row" >
  <ng-container *ngIf="cardsSearch && cardsSearch.length > 0">
    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-1" style="min-height: 300px;" *ngFor="let card of cardsSearch; let i = index">
      <div

      
      >
        <div class="card-img" style="height: 200px; cursor: pointer;">
          <div class="heart">
            <div class="heart-circle">
              <mat-icon
                class="heart-icon heart-icon-red"
                (click)="changeColor(i)"
                *ngIf="favorites.has(card.id)"
                >favorite</mat-icon
              >
              <mat-icon
                class="heart-icon heart-icon-grey"
                (click)="changeColor(i)"
                *ngIf="!favorites.has(card.id)"
                >favorite</mat-icon
              >
            </div>
          </div>
          <div class="d-flex align-items-start justify-content-start h-100" (click)="goToDetails(card.id)" role="button">
            <img [src]="card.mainImage" alt=""  />
          </div>
          <!--'https://wovenclouds.com/arrovia/api/uploads?path='+-->
          <div class="title-rating flex"   (click)="goToDetails(card.id)" role="button">
            <div class="title">
              <p [matTooltip]="card.nameAr">{{!isFlipped? card.nameAr:card.nameEn }}</p>
            </div>
            <div class="rating" *ngIf="card.averageRating > 0">
              <p>{{ card.averageRating.toFixed(1) }}</p>
            </div>
          </div>
          <div class="location flex muted" (click)="goToDetails(card.id)" >
            <p [ngClass]="{'mt-2 mb-2': !card.website, 'custom-margin': card.website}">
              {{ card.city.length > 30 ? (card.city | slice:0:30) + '...' : card.city }}
            </p>
          <div class="comma" *ngIf="card.website">
              <p class="mt-2 mb-1" *ngIf="card.website">،</p>
            </div>
            <p class="custom-margin">{{ card.website }}</p>
          </div>
          <div class="rate" (click)="goToDetails(card.id)" >
            <div class="price">
              <!-- <p class="number">{{ card.price | number : "1.2-2" }}</p> -->
              <p class="currency">{{ card.price | number : "1.2-2"  }} {{'ASR'|translate}}</p>
            </div>
            <div>
              <p class="muted modify-font">{{'per_night'|translate}}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container >
</div>
