<form [formGroup]="searchTrip" class="container" dir="auto" style="text-align: start;">
  <div class="width">
    <div class="customContainer">
      <div class="shadow">
        <div class="padding-2 row">
          <div class="search col-sm-12 col-md-4 col-lg-4 px-2">
            <div class="box cont-pad">
              <p class="title mb-0">{{'search_destination' | translate }}</p>
              <div class="autoComplete">
                <ng-autocomplete  [data]="data" [searchKeyword]="keyword" placeholder="{{'search_destination' | translate }}"
                  (selected)='selectEvent($event)' (inputChanged)="searchingCity($event)" [debounceTime]="1250"
                  [isLoading]="isLoading" [itemTemplate]="itemTemplate" (inputCleared)="onInputClered()">
                </ng-autocomplete>
                
                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.city"></a>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="date col-sm-12 col-md-4 col-lg-4 px-2">
            <div class="box cont-pad flex-date">
              <div class="search-inner search-space w-50">
                <p class="title ms-2">{{'check_in_date' | translate }}</p>
                <div class="search-results">
                  <div class="d-flex align-items-center" style="cursor: pointer;">
                    <span (click)="openDatePicker()" class="title-1 fs-5 ms-2"
                      [ngClass]="{'opacity-1':!searchTrip.get('startDate')?.value }">{{searchTrip.get('startDate')?.value
                      ?searchTrip.get('startDate')?.value :('check_in_date' | translate) }}</span>
                    <img src="../../assets/icons/calendar.svg" style="opacity: 0.2" alt=""
                      *ngIf="!searchTrip.get('startDate')?.value" />
                    <img src="../../assets/icons/calendar.svg" style="margin-left: 5px;" alt="" *ngIf="searchTrip.get('startDate')?.value" />
                  </div>
                </div>
                <div class="buttons" *ngIf="searchTrip.get('startDate')?.value">
                  <p class="prev hovered-pointer pe-2" (click)="handelPrevStartDate()">{{'prev'| translate}}</p>
                  <p class="next hovered-pointer" (click)="handelNextStarDate()">{{'next'|translate}}</p>
                </div>
              </div>
              <div class="search-inner search-space w-50">
                <p class="title ms-2">{{'check_out_date'| translate}}</p>
                <div class="search-results">
                  <div class="d-flex align-items-center" style="cursor: pointer;">
                    <span (click)="openDatePicker()" class="title-1 fs-5 ms-2"
                      [ngClass]="{'opacity-1':!searchTrip.get('endDate')?.value }">{{searchTrip.get('endDate')?.value ?
                      searchTrip.get('endDate')?.value : ('select_date'| translate) }}</span>
                    <img src="../../assets/icons/calendar.svg" style="opacity: 0.2" alt=""
                      *ngIf="!searchTrip.get('endDate')?.value" />
                    <img src="../../assets/icons/calendar.svg" style="margin-left: 5px;" alt="" *ngIf="searchTrip.get('endDate')?.value" />
                  </div>
                </div>
                <div class="buttons" *ngIf="searchTrip.get('endDate')?.value">
                  <p class="prev hovered-pointer pe-2" (click)="handelPrevEndDate()">{{'prev'|translate}}</p>
                  <p class="next hovered-pointer" (click)="handelNextEndDate()">{{'next'| translate}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="guest col-sm-12 col-md-4 col-lg-4 px-2">
            <div class="box cont-pad w-100">
              <p class="title mb-0 ">{{'guests'|translate}}</p>
              <div class="btn-group w-100 mt-2" dropdown #dropdown="bs-dropdown" [insideClick]="true"
                [autoClose]="true">
                <div dropdownToggle>
                  <span class="fs-5 title-1 hovered-pointer" style="opacity: 0.2"
                    *ngIf="searchTrip.get('adults')?.value === 0">
                    {{'number_of_guests'|translate}}
                  </span>
                </div>
                <span *ngIf="searchTrip.get('adults')?.value > 0" class="fs-5 fw-bold hovered-pointer" (click)="dropdown.toggle(true)">
                  {{ searchTrip.get('adults')?.value }} {{ 'adults' | translate }}&nbsp;.  
                  {{ searchTrip.get('children')?.value }} {{ 'children' | translate }}
                </span>
                <ul *dropdownMenu id="dropdown-animated" class="dropdown-menu mt-4 p-4 w-100 row" role="menu">
                  <li role="menuitem" class="my-3">
                    <!-- <div class="d-flex align-items-end mb-2">
                      <button class="btn btn-sm shadow-none text-right" (click)="dropdown.toggle(true)">x</button>
                    </div> -->
                    <div class="d-flex align-items-center justify-content-between ">
                      <div class="flex align-items-center justify-content-between gap-3">
                        <span class="ms-2" (click)="plus('adults')"
                          >
                          <svg class="hovered-pointer" xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
                            <g id="Icon_ionic-ios-add-circle-outline" data-name="Icon ionic-ios-add-circle-outline" transform="translate(-3.375 -3.375)">
                              <path id="Path_543" data-name="Path 543" d="M24.363,16.875H19.125V11.637a1.125,1.125,0,0,0-2.25,0v5.238H11.637A1.077,1.077,0,0,0,10.512,18a1.089,1.089,0,0,0,1.125,1.125h5.238v5.238A1.09,1.09,0,0,0,18,25.488a1.119,1.119,0,0,0,1.125-1.125V19.125h5.238a1.125,1.125,0,0,0,0-2.25Z"/>
                              <path id="Path_544" data-name="Path 544" d="M18,5.344A12.651,12.651,0,1,1,9.049,9.049,12.573,12.573,0,0,1,18,5.344m0-1.969A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z"/>
                            </g>
                          </svg>                          
                        </span>
                        <span>{{searchTrip.get('adults')?.value}}</span>
                        <button class="btn-cust me-2" (click)="minus('adults')"
                          [disabled]="searchTrip.get('adults')?.value === 0"
                          >
                          <svg 
                          [ngClass]="{ 'stroke-border-gray':searchTrip.get('adults')?.value === 0,'stroke-border-black':searchTrip.get('adults')?.value > 0}"
                          xmlns="http://www.w3.org/2000/svg" width="29.301" height="29.301" viewBox="0 0 29.301 29.301">
                            <g id="Icon_feather-minus-circle" data-name="Icon feather-minus-circle" transform="translate(-2 -2)">
                              <path id="Path_545" data-name="Path 545" d="M30.3,16.651A13.651,13.651,0,1,1,16.651,3,13.651,13.651,0,0,1,30.3,16.651Z" transform="translate(0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                              <path id="Path_546" data-name="Path 546" d="M12,18H22.921" transform="translate(-0.81 -1.349)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </g>
                          </svg>                                                   
                      
                        </button>
                      </div>
                      <div class="d-flex align-items-start justify-content-start flex-column">
                        <span class="fs-19 ms-3">{{'adults'| translate}}</span>
                      </div>
                    </div>
                  </li>
                  <li role="menuitem" class="my-3">
                    <div class="d-flex align-items-center justify-content-between ">
                      <div class="flex align-items-center justify-content-between gap-3">
                        <button class=" btn-cust ms-2" (click)="plus('children')"
                          [disabled]="searchTrip.get('adults')?.value === 0"     
                            ><svg
                            class="hovered-pointer"
                            [ngClass]="{'btn-border-gray': searchTrip.get('adults')?.value === 0 , 'btn-border-black' : searchTrip.get('adults')?.value !== 0}"
                            xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
                            <g id="Icon_ionic-ios-add-circle-outline" data-name="Icon ionic-ios-add-circle-outline"
                              transform="translate(-3.375 -3.375)">
                              <path id="Path_543" data-name="Path 543"
                                d="M24.363,16.875H19.125V11.637a1.125,1.125,0,0,0-2.25,0v5.238H11.637A1.077,1.077,0,0,0,10.512,18a1.089,1.089,0,0,0,1.125,1.125h5.238v5.238A1.09,1.09,0,0,0,18,25.488a1.119,1.119,0,0,0,1.125-1.125V19.125h5.238a1.125,1.125,0,0,0,0-2.25Z" />
                              <path id="Path_544" data-name="Path 544"
                                d="M18,5.344A12.651,12.651,0,1,1,9.049,9.049,12.573,12.573,0,0,1,18,5.344m0-1.969A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z" />
                            </g>
                          </svg>
                        </button>
                        <span>{{searchTrip.get('children')?.value}}</span>
                        <button class="btn-cust me-2" (click)="minus('children')"
                          [disabled]="searchTrip.get('children')?.value === 0"
                          >
                          <svg 
                          [ngClass]="{ 'stroke-border-gray':searchTrip.get('children')?.value === 0,'stroke-border-black':searchTrip.get('children')?.value > 0}"
                          xmlns="http://www.w3.org/2000/svg" width="29.301" height="29.301" viewBox="0 0 29.301 29.301">
                            <g id="Icon_feather-minus-circle" data-name="Icon feather-minus-circle" transform="translate(-2 -2)">
                              <path id="Path_545" data-name="Path 545" d="M30.3,16.651A13.651,13.651,0,1,1,16.651,3,13.651,13.651,0,0,1,30.3,16.651Z" transform="translate(0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                              <path id="Path_546" data-name="Path 546" d="M12,18H22.921" transform="translate(-0.81 -1.349)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </g>
                          </svg>                                                  
                        </button>
                      </div>
                      <div class="d-flex align-items-start justify-content-start flex-column">
                        <span class="fs-19">{{'children'| translate}}</span>
                      </div>
                    </div>
                  </li>
       
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="search-icon-container">
      <div class="search-icon" (click)="onSearchTrip()">
        <div>
          <!-- <img src="assets/aroovia-icons/Icon feather-search.svg" alt="" /> -->
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24">
            <g fill="none" fill-rule="evenodd">
              <path
                d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
              <path fill="#ffffff"
                d="M10.5 2a8.5 8.5 0 1 0 5.262 15.176l3.652 3.652a1 1 0 0 0 1.414-1.414l-3.652-3.652A8.5 8.5 0 0 0 10.5 2M4 10.5a6.5 6.5 0 1 1 13 0a6.5 6.5 0 0 1-13 0" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</form>