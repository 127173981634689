import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CancellationPolicyDTO } from 'app/rooms-managment/rooms-dto';

@Component({
  selector: 'app-cancel-policy-dialog',
  templateUrl: './cancel-policy-dialog.component.html',
  styleUrls: ['./cancel-policy-dialog.component.css']
})
export class CancelPolicyDialogComponent {
  cancelData:CancellationPolicyDTO
constructor(
  public dialogRef: MatDialogRef<any>,
  @Inject(MAT_DIALOG_DATA) public data: CancellationPolicyDTO,
  public translation:TranslateService
){
 this.cancelData = data

}


closeDialog(){
  this.dialogRef.close()
}
}
