import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PropertyDTO } from 'app/new-property/property-dto';
import {
  ReservationDTO,
  ReservationRoomsDTO,
} from 'app/reservation-management/reservation-dto';
import { RoomsDTO } from 'app/rooms-managment/rooms-dto';
import { SearchDTO } from 'app/search-details/search-dto';
import { userData } from 'app/services/auth/authentication.service';
import { DataContextService } from 'app/services/global-context/data-context.service';

@Component({
  selector: 'app-final-review-reservation',
  templateUrl: './final-review-reservation.component.html',
  styleUrls: ['./final-review-reservation.component.css'],
})
export class FinalReviewReservationComponent implements OnInit, OnDestroy {
  @Input() hotelDetails: SearchDTO | null = null;
  property: PropertyDTO;
  rooms: RoomsDTO[];
  startDate: string;
  entDate: string;
  dateOfReservation: string;
  gustes: { children: number; adults: number; infants: number };
  userInfo: userData;
  reservationInfo: ReservationDTO; // only if he came from reservations details page
  constructor(
    private router: Router,
    private datacontext: DataContextService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}
  options: google.maps.MapOptions = {
    mapId: 'Arrovia',
    center: { lat: 23.885942, lng: 45.079162 },
    zoom: 8,
    disableDoubleClickZoom: true,
    maxZoom: 8,
    disableDefaultUI: true,
    minZoom: 8,
  };
  markerPosition: google.maps.LatLngLiteral = {
    lat: 23.885942,
    lng: 45.079162,
  };
  isFlipped = false;
  openMapInNewTab(): void {
    const url = `https://www.google.com/maps?q=${this.options.center?.lat},${this.options.center?.lng}&z=${this.options.zoom}`;
    console.log(url);
    window.open(url, '_blank');
  }
  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    const details = JSON.parse(localStorage.getItem('reservationDetails')!);
    console.log(details);
    if (details) {
      this.property = details.Property;
      this.options.center!.lat = Number(this.property.mapLat);
      this.options.center!.lng = Number(this.property.mapLon);
      this.markerPosition = {
        lat: Number(this.property.mapLat),
        lng: Number(this.property.mapLon),
      };
      this.rooms = details.ReservationRooms.map(
        (resRoom: ReservationRoomsDTO) => resRoom.Room
      );
      this.dateOfReservation = this.dateFormate(new Date(details.createdAt));
      this.reservationInfo = details;
      // this.options = {
      //   ...this.options,
      //   center:{lat:details.Property.mapLat, lng:details.Property.mapLon}
      // }
      // this.markerPosition = {lat:details.Property.mapLat, lng:details.Property.mapLon}
    }
    this.userInfo = this.datacontext.userData!;
  }

  getDateStartEnd(date: string) {
    return this.dateFormate(new Date(date));
  }
  completePayment() {
    const token = this.datacontext.getRouteParams('token');
    const reservationId = this.reservationInfo.id;
    const price = this.reservationInfo.netPrice;
    console.log(`checkout?reserevationId=${reservationId}&price=${price}`);
    window.location.href =
   `checkout?reserevationId=${reservationId}&price=${price}&createdAt=${this.reservationInfo.createdAt}`;
    // this.route.navigate([`checkout?reserevationId=${reservationId}&price=${price}`], {
    //   queryParams: { reservationId, price },
    // });
  }
  dateFormate(date: Date): string {
    const months = [
      'يناير',
      'فبراير',
      'مارس',
      'أبريل',
      'مايو',
      'يونيو',
      'يوليو',
      'أغسطس',
      'سبتمبر',
      'أكتوبر',
      'نوفمبر',
      'ديسمبر',
    ];

    // const convertedDate = new Date(date);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  ngOnDestroy(): void {
    const details = JSON.parse(localStorage.getItem('reservationDetails')!);
    if (details) {
      localStorage.removeItem('reservationDetails');
    }
  }
}
