<div class="reserve align-items-center" id="reservationId" [dir]="isFlipped ? 'rtl' : 'ltr'">
  <div class="reserve-1">
    <p style="cursor: pointer" (click)="onHandelReservation(reservationDetails)" class="p-auto">
     {{'book_now'|translate}}
    </p>
  </div>
  <!-- <div class="reserve-2" >
    <p class="grey mb-1">اجمالي السعر</p>
    <div class="reserve-2-price">
      <span>ريال</span>
      <p class="subtitle mb-1">
        {{ calcTotalPrice() | number : "1.1-2" }}
      </p>
    </div>
  </div> -->
  <div class="reserve-3" dropdown #dropdown="bs-dropdown" [insideClick]="true" [autoClose]="true">
    <p class="grey direction mb-1" style="text-align: start;" dir="auto">
      {{ +tempSearchResult.children + +tempSearchResult.adults }} {{'guests'|translate}}
    </p>
    <div class="btn-group w-100 mt-2" dropdown #dropdown="bs-dropdown" [insideClick]="true" [autoClose]="true"  style="text-align: start;" dir="auto">
      <span class="subtitle direction fw-bold" dropdownToggle aria-controls="dropdown-animated">
        {{
        tempSearchResult.adults +" "+
        ('adults'|translate)  +" "+
        (tempSearchResult.children || 0) +" "+
        ('children'|translate)
        }}
          <svg  [ngStyle]="{ transform: !isFlipped ? 'rotate(0deg)' : 'rotate(180deg)' }" xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 24 24">
            <path fill="#141414"
              d="m8.82 12l7.715 7.716q.22.22.218.528t-.224.529q-.221.221-.529.221t-.529-.221L7.83 13.136q-.243-.242-.354-.54q-.112-.298-.112-.596t.112-.596t.354-.54l7.64-7.644q.221-.221.532-.218q.31.003.531.224t.222.529t-.222.528z" />
          </svg>
      </span>
      <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu p-4" role="menu" aria-labelledby="button-animated">
        <li role="menuitem" class="my-3">
          <div class="d-flex align-items-center justify-content-between">
            <div class="flex align-items-center  justify-content-center gap-2">
              <button class="btn-cust ms-2" (click)="plus('adults')">
                <svg  [ngStyle]="{ transform: !isFlipped ? 'rotate(0deg)' : 'rotate(180deg)' }" class="hovered-pointer" xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25"
                  viewBox="0 0 29.25 29.25">
                  <g id="Icon_ionic-ios-add-circle-outline" data-name="Icon ionic-ios-add-circle-outline"
                    transform="translate(-3.375 -3.375)">
                    <path id="Path_543" data-name="Path 543"
                      d="M24.363,16.875H19.125V11.637a1.125,1.125,0,0,0-2.25,0v5.238H11.637A1.077,1.077,0,0,0,10.512,18a1.089,1.089,0,0,0,1.125,1.125h5.238v5.238A1.09,1.09,0,0,0,18,25.488a1.119,1.119,0,0,0,1.125-1.125V19.125h5.238a1.125,1.125,0,0,0,0-2.25Z" />
                    <path id="Path_544" data-name="Path 544"
                      d="M18,5.344A12.651,12.651,0,1,1,9.049,9.049,12.573,12.573,0,0,1,18,5.344m0-1.969A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z" />
                  </g>
                </svg>
              </button>
              <span>{{ tempSearchResult.adults }}</span>
              <button class="btn-cust me-2" (click)="minus('adults')" [disabled]="tempSearchResult.adults === 0">
                <svg
                  [ngClass]="{ 'stroke-border-gray':tempSearchResult.adults === 0,'stroke-border-black':tempSearchResult.adults > 0}"
                  xmlns="http://www.w3.org/2000/svg" width="29.301" height="29.301" viewBox="0 0 29.301 29.301">
                  <g id="Icon_feather-minus-circle" data-name="Icon feather-minus-circle" transform="translate(-2 -2)">
                    <path id="Path_545" data-name="Path 545"
                      d="M30.3,16.651A13.651,13.651,0,1,1,16.651,3,13.651,13.651,0,0,1,30.3,16.651Z"
                      transform="translate(0)" fill="none" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2" />
                    <path id="Path_546" data-name="Path 546" d="M12,18H22.921" transform="translate(-0.81 -1.349)"
                      fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  </g>
                </svg>
              </button>
            </div>
            <div class="d-flex w-100 flex-column align-items-end ms-3">
              <!-- <span style="color: #ccc; font-size: 10px">اكبر من 13 عام</span> -->
              <span>{{'adults'|translate}}</span>
            </div>
          </div>
        </li>
        <li role="menuitem" class="my-3">
          <div class="d-flex align-items-center justify-content-between">
            <div class="flex align-items-center justify-content-center  gap-2">
              <button class="btn-cust ms-2" [disabled]="tempSearchResult.adults === 0" (click)="plus('children')">
                <svg class="hovered-pointer"
                  [ngClass]="{'btn-border-gray': tempSearchResult.adults === 0 , 'btn-border-black' : tempSearchResult.adults !== 0}"
                  xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
                  <g id="Icon_ionic-ios-add-circle-outline" data-name="Icon ionic-ios-add-circle-outline"
                    transform="translate(-3.375 -3.375)">
                    <path id="Path_543" data-name="Path 543"
                      d="M24.363,16.875H19.125V11.637a1.125,1.125,0,0,0-2.25,0v5.238H11.637A1.077,1.077,0,0,0,10.512,18a1.089,1.089,0,0,0,1.125,1.125h5.238v5.238A1.09,1.09,0,0,0,18,25.488a1.119,1.119,0,0,0,1.125-1.125V19.125h5.238a1.125,1.125,0,0,0,0-2.25Z" />
                    <path id="Path_544" data-name="Path 544"
                      d="M18,5.344A12.651,12.651,0,1,1,9.049,9.049,12.573,12.573,0,0,1,18,5.344m0-1.969A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z" />
                  </g>
                </svg>
              </button>
              <span>{{ tempSearchResult.children || 0 }}</span>
              <button class="btn-cust me-2" (click)="minus('children')" [disabled]="tempSearchResult.children === 0">
                <svg
                  [ngClass]="{ 'stroke-border-gray':tempSearchResult.children === 0,'stroke-border-black':tempSearchResult.children > 0}"
                  xmlns="http://www.w3.org/2000/svg" width="29.301" height="29.301" viewBox="0 0 29.301 29.301">
                  <g id="Icon_feather-minus-circle" data-name="Icon feather-minus-circle" transform="translate(-2 -2)">
                    <path id="Path_545" data-name="Path 545"
                      d="M30.3,16.651A13.651,13.651,0,1,1,16.651,3,13.651,13.651,0,0,1,30.3,16.651Z"
                      transform="translate(0)" fill="none" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2" />
                    <path id="Path_546" data-name="Path 546" d="M12,18H22.921" transform="translate(-0.81 -1.349)"
                      fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  </g>
                </svg>
              </button>
            </div>
            <div class="d-flex w-100 flex-column align-items-end">
              <!-- <span style="color: #ccc; font-size: 10px">عمر 2 - عام</span> -->
              <span>{{'children'|translate}}</span>
            </div>
          </div>
        </li>
        <!-- <li role="menuitem" class="my-3" *ngIf="tempSearchResult.children > 0">
          <div class="d-flex align-items-center justify-content-between">
            <div class="flex align-items-center justify-content-center  gap-2">
              <button class="btn-cust ms-2" [disabled]="tempSearchResult.adults === 0" (click)="plus('infants')">
                <svg class="hovered-pointer"
                  [ngClass]="{'btn-border-gray': tempSearchResult.adults === 0 , 'btn-border-black' : tempSearchResult.adults !== 0}"
                  xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
                  <g id="Icon_ionic-ios-add-circle-outline" data-name="Icon ionic-ios-add-circle-outline"
                    transform="translate(-3.375 -3.375)">
                    <path id="Path_543" data-name="Path 543"
                      d="M24.363,16.875H19.125V11.637a1.125,1.125,0,0,0-2.25,0v5.238H11.637A1.077,1.077,0,0,0,10.512,18a1.089,1.089,0,0,0,1.125,1.125h5.238v5.238A1.09,1.09,0,0,0,18,25.488a1.119,1.119,0,0,0,1.125-1.125V19.125h5.238a1.125,1.125,0,0,0,0-2.25Z" />
                    <path id="Path_544" data-name="Path 544"
                      d="M18,5.344A12.651,12.651,0,1,1,9.049,9.049,12.573,12.573,0,0,1,18,5.344m0-1.969A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z" />
                  </g>
                </svg>
              </button>
              <span>{{ tempSearchResult.infants || 0 }}</span>
              <button class="btn-cust me-2" (click)="minus('infants')" [disabled]="tempSearchResult.infants === 0"
               
               >
                <svg
                  [ngClass]="{ 'stroke-border-gray':tempSearchResult.infants === 0,'stroke-border-black':tempSearchResult.infants > 0}"
                  xmlns="http://www.w3.org/2000/svg" width="29.301" height="29.301" viewBox="0 0 29.301 29.301">
                  <g id="Icon_feather-minus-circle" data-name="Icon feather-minus-circle" transform="translate(-2 -2)">
                    <path id="Path_545" data-name="Path 545"
                      d="M30.3,16.651A13.651,13.651,0,1,1,16.651,3,13.651,13.651,0,0,1,30.3,16.651Z"
                      transform="translate(0)" fill="none" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2" />
                    <path id="Path_546" data-name="Path 546" d="M12,18H22.921" transform="translate(-0.81 -1.349)"
                      fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  </g>
                </svg>
              </button>
            </div>
            <div class="d-flex w-100 flex-column align-items-end">
              <span>رضع</span>
            </div>
          </div>
        </li> -->
      </ul>
    </div>
    <!-- <p *ngIf="!tempSearchResult" class="fw-bold">{{reservationData.guestsType}}</p> -->
  </div>
  <div class="reserve-4">
    <p class="grey direction mb-1" style="text-align: start;" dir="auto">
      <!-- {{ reservationData.stayDates }} -->
      {{ nightCount === 0 ? nightCount +" "+ ('night'|translate) : nightCount+ " "+ ('nights'|translate) }}
    </p>
    <div class="d-flex align-items-center justify-content-end " style="text-align: start;" dir="auto">
      <svg *ngIf="currentStartedDate.length > 0" xmlns="http://www.w3.org/2000/svg" style="font-family: 'black'"
        width="32" height="16" viewBox="0 0 24 24">
        <path fill="#000"
          d="m8.82 12l7.715 7.716q.22.22.218.528t-.224.529q-.221.221-.529.221t-.529-.221L7.83 13.136q-.243-.242-.354-.54q-.112-.298-.112-.596t.112-.596t.354-.54l7.64-7.644q.221-.221.532-.218q.31.003.531.224t.222.529t-.222.528z" />
      </svg>
      <span *ngIf="!dateChosen" style="opacity: .2; font-family: 'black';" (click)="openDatepicker()">
         {{'choose_date'|translate}}</span>
      <span *ngIf="dateChosen" (click)="openDatepicker()" dir="auto">{{dateChosen}}</span>
      <!-- <input role="button" placeholder="اختر تاريخ" class="p-0 form-control shadow-none border-0 w-50 date-input"
        #daterangepicker="bsDaterangepicker" style="text-align: right" bsDaterangepicker [bsConfig]="bsConfig"
        [(bsValue)]="currentStartedDate" (bsValueChange)="calcNights()" #picker /> -->
    </div>
  </div>
  <div class="reserve-5">
    <p class="grey mb-1 " style="text-align: end;">{{'price_per_night'|translate}}</p>
    <div class="d-flex align-items gap-1">
      <span> {{'ASR'|translate}} </span>
      <p class="subtitle">
       <span [ngStyle]="{'text-decoration':reservationDetails?.priceAfterDiscount ? 'line-through' : 'none' }"> {{ reservationDetails && reservationDetails.price | number:'1.2-2' }}</span> <span [ngStyle]="{
        'display': reservationDetails?.priceAfterDiscount ? 'inline' : 'none',
        margin:'0 5px'
       }">-</span> {{reservationDetails && reservationDetails.priceAfterDiscount | number:'1.2-2'}}
      </p>
    </div>
  </div>
</div>