import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchDTO } from 'app/search-details/search-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, retry, throwError } from 'rxjs';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css'],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  @BlockUI('root') rootBlocker!: NgBlockUI;
  currentLang: string; // Variable to store the current language

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private datacontext: DataContextService,
    private translate: TranslateService // Inject TranslateService
  ) {
    this.currentLang = this.translate.currentLang; // Get the current language
  }
  searchParams: any = null;
  isDestination: boolean = false;
  isType: boolean = false;
  isFlipped = false;
  isLangEn: boolean;
  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });

    this.activatedRoute.queryParams.subscribe((data: any) => {
      if (
        !data.destination ||
        !data.startDate ||
        !data.destination ||
        !data.adults ||
        !data.children
      ) {
        // call random api

        this.isDestination = localStorage.getItem('destination') ? true : false;
        this.isType = localStorage.getItem('typeId') ? true : false;
        if (this.isDestination) {
          this.getDestination();
        } else if (this.isType) {
          this.getSearchRandom();
        }
      } else {
        /// get all query params and search
        const bodyData = {
          city: data.destination,
          arrivalDate: data.startDate,
          departureDate: data.endDate,
          adults: data.adults,
          childerns: data.children,
          limit: this.limit,
          price: this.priceSort,
          page: this.currentNumber,
          stars: this.starSort,
          rate: this.rateSort,
          rooms: data.rooms,
        };
        this.searchParams = bodyData;
        this.getSearchResult(bodyData);
      }
    });
  }
  detectLanguage(): string {
    // Implement your language detection logic here
    return this.translate.currentLang || this.translate.defaultLang;
  }

  searchResult: SearchDTO[];
  private getSearchResult(data: any) {
    // this.rootBlocker.start();
    this.datacontext
      .postData(`/search/filter-distinations`, data)
      .pipe(
        retry(2),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            // this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe((value: any) => {
        console.log(
          'price',
          this.priceSort,
          ' & rate',
          this.rateSort,
          ' & star',
          this.starSort
        );
        this.searchResult = value.data;

        if (this.priceSort == 'asc')
          this.searchResult = value.data.sort(function (a: any, b: any) {
            return a.price - b.price;
          });
        else if (this.priceSort == 'desc')
          this.searchResult = value.data.sort(function (a: any, b: any) {
            return b.price - a.price;
          });

        if (this.rateSort == 'asc')
          this.searchResult = value.data.sort(function (a: any, b: any) {
            return a.averageRating - b.averageRating;
          });
        else if (this.rateSort == 'desc')
          this.searchResult = value.data.sort(function (a: any, b: any) {
            return b.averageRating - a.averageRating;
          });

        if (this.starSort == 'asc')
          this.searchResult = value.data.sort(function (a: any, b: any) {
            return a.hotelStars - b.hotelStars;
          });
        else if (this.starSort == 'desc')
          this.searchResult = value.data.sort(function (a: any, b: any) {
            return b.hotelStars - a.hotelStars;
          });

        if (this.searchResult && this.searchResult.length) {
          console.log(
            'hotelStars: ',
            this.searchResult[0].hotelStars,
            this.searchResult[1].hotelStars,
            this.searchResult[2].hotelStars,
            this.searchResult[3].hotelStars
          );
          console.log(
            'price: ',
            this.searchResult[0].price,
            this.searchResult[1].price,
            this.searchResult[2].price,
            this.searchResult[3].price
          );
          console.log(
            'averageRating: ',
            this.searchResult[0].averageRating,
            this.searchResult[1].averageRating,
            this.searchResult[2].averageRating,
            this.searchResult[3].averageRating
          );
        }

        // this.rootBlocker.stop();
      });
  }

  private getDestination() {
    const destination = JSON.parse(localStorage.getItem('destination')!);

    const currentDate = new Date().toLocaleDateString();
    const nextDate = new Date(new Date().getDay() + 3).toLocaleDateString();
    const dummyData = {
      id: destination.id,
      arrivalDate: this.datacontext.convertDateAPI(currentDate),
      departureDate: this.datacontext.convertDateAPI(nextDate),
      adults: '3',
      childerns: '1',
      limit: this.limit,
      page: this.currentNumber,
      price: this.priceSort,
      stars: this.starSort,
      rate: this.rateSort,
    };

    // this.rootBlocker.start();
    this.datacontext
      .postData(`/search/filter-destinations`, dummyData)
      .pipe(
        retry(2),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            // this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe((value: any) => {
        if (value.success) {
          this.searchResult = value.data;
          this.totalPages = value.totalPages;
        }
        // this.rootBlocker.stop();
      });
  }
  totalPages: number = 1;
  private getSearchRandom() {
    const type = JSON.parse(localStorage.getItem('typeId')!);

    const currentDate = new Date().toLocaleDateString();
    const nextDate = new Date(new Date().getDay() + 3).toLocaleDateString();
    const dummyData = {
      id: type.id,
      arrivalDate: this.datacontext.convertDateAPI(currentDate),
      departureDate: this.datacontext.convertDateAPI(nextDate),
      adults: '3',
      childerns: '1',
      limit: this.limit,
      page: this.currentNumber,
      price: this.priceSort,
      stars: this.starSort,
      rate: this.rateSort,
    };
    // this.rootBlocker.start();
    this.datacontext
      .postData(`/search/type`, dummyData)
      .pipe(
        retry(2),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            // this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe((value: any) => {
        if (value.success) {
          this.searchResult = value.data;
          this.totalPages = value.totalPages;
        }
        // this.rootBlocker.stop();
      });
  }
  currentNumber: number = 1;
  limit = 10;
  next(): void {
    ++this.currentNumber;
    if (this.isDestination) {
      this.getDestination();
    } else if (this.isType) {
      this.getSearchRandom();
    }
  }

  previous(): void {
    if (this.currentNumber > 1) {
      --this.currentNumber;
      if (this.isDestination) {
        this.getDestination();
      } else if (this.isType) {
        this.getSearchRandom();
      }
    }
  }

  navigateHome() {
    this.route.navigate(['/']);
  }

  priceSort: string = '';
  starSort: string = '';
  rateSort: string = '';
  sortPrice(event: Event, order?: string) {
    if (order === 'asc') {
      this.priceSort = 'asc';
    } else {
      this.priceSort = 'desc';
    }
    this.ngOnInit();
  }
  sortStars(event: Event, order: string) {
    if (order === 'desc') {
      this.starSort = 'desc';
    } else {
      this.starSort = 'asc';
    }
    this.ngOnInit();
  }
  sortRate(event: Event, order: string) {
    if (order === 'desc') {
      this.rateSort = 'desc';
    } else {
      this.rateSort = 'asc';
    }
    this.ngOnInit();
  }
  clearFilter() {
    this.priceSort = '';
    this.rateSort = '';
    this.starSort = '';

    this.ngOnInit();
  }
  ngOnDestroy(): void {
    if (this.isDestination) {
      const isDestination = localStorage.getItem('destination');
      localStorage.removeItem('destination');
    }
    if (this.isType) {
      const type = JSON.parse(localStorage.getItem('typeId')!);
      localStorage.removeItem(type);
    }
  }

  handelSearchDateConvert(date: string) {
    const months = [
      'يناير',
      'فبراير',
      'مارس',
      'أبريل',
      'مايو',
      'يونيو',
      'يوليو',
      'أغسطس',
      'سبتمبر',
      'أكتوبر',
      'نوفمبر',
      'ديسمبر',
    ];
    let startDateConvert = this.datacontext.convertDateAPI(date);
    const newStartDate = new Date(startDateConvert);

    const startDateOut = `${newStartDate.getDate()} ${
      months[newStartDate.getMonth()]
    }`;

    return startDateOut;
  }
}
