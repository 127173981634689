import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { retry } from 'rxjs';
import { SearchDTO } from './search-dto';

@Component({
  selector: 'search-details',
  templateUrl: './search-details.component.html',
  styleUrls: ['./search-details.component.css'],
})
export class SearchDetails implements OnInit {
  sharedNetPrice_BE: number = 0;

  @BlockUI('root') rootBlocker!: NgBlockUI;
  id = 0;
  constructor(
    private activatedRoute: ActivatedRoute,
    private datacontext: DataContextService,
    private router: Router
  ) {
    this.id = this.activatedRoute.snapshot.params['id'];
  }
  onSharedNetPriceChange(newPrice: number) {
    this.sharedNetPrice_BE = newPrice;
  }
  totalPriceTemp: number = 0;

  searchDataResult: SearchDTO;
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((param: any) => {
      const id = param['id'];

      if (id) {
        //calling api
        this.gettingDetails(id);
      }
    });
  }
  private gettingDetails(id: string) {
    // this.rootBlocker.start()
    const token = this.datacontext.getRouteParams('token');
    let url;
    if (token) {
      url = `/search/find/${id}?token=${token}`;
    } else {
      url = `/search/find/${id}`;
    }
    this.datacontext
      .getData(url)
      .pipe(retry(2))
      .subscribe({
        next: (value: any) => {
          if (value.success) {
            this.searchDataResult = value.data[0];
            console.log(this.searchDataResult);
            this.searchDataResult.Rooms = this.searchDataResult.Rooms.map(
              (room) => {
                return {
                  ...room,
                  roomCount: 0,
                };
              }
            );
          }
          // this.rootBlocker.stop()
        },
      });
  }
}
