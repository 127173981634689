<app-categories></app-categories>
<div class="container" dir="auto" style="text-align: start;">
  <app-navigation></app-navigation>
  <div class="header">
    <div class="left-container"   style="text-align: start;">
      <div class="d-flex align-items-center gap-1">
        <p class="search-results mb-0">{{'search_result'|translate}}</p>
        <p class="fs-6 regular mb-0" *ngIf="searchResult"
        >
        ({{ searchResult && searchResult.length }}
          <span>{{'result'|translate}}</span>)
          </p>

      </div>
      <div class="location-container">
        <p class="date mb-0" *ngIf="searchParams" dir="auto" style="text-align: start;">
          <span>
            {{   searchParams.city +" ,"+" "+('from'|translate)}}
          </span>
          <span>
            {{    handelSearchDateConvert(searchParams.arrivalDate) }}
          </span>
          <span>
            {{    ('to'|translate) }}
          </span>
          <span>
            {{      handelSearchDateConvert(searchParams.departureDate) }}
          </span>
          <span>
            {{ ','+ searchParams.childerns + " "+('children'|translate) }} 
          </span>
          <span>
            {{ +searchParams.adults+" "+('adults'|translate) }}
          </span>
          <!-- {{
              handelSearchDateConvert(searchParams.arrivalDate) +" "+('to'|translate)+" "+
              handelSearchDateConvert(searchParams.departureDate) +"  ," +searchParams.childerns+" "+('children'|translate)
              +" "+ " ,"+searchParams.adults+" "+('adults'|translate)
          }} -->
        </p>
      </div>

    </div>
    <div class="" style="text-align: start;">
      <div
        class="btn-group w-100 mt-2"
        dropdown
        #dropdown="bs-dropdown"
        [insideClick]="true"
        [autoClose]="true"
        placement="bottom center"
      >
        <div dropdownToggle class="d-flex gap-1" id="button-alignment" aria-controls="dropdown-alignment">
          <div class="filter-icon">
            <div class="purple">
              <img
                src="../../assets/aroovia-icons/Filter.svg"
                alt=""
                style="border-radius: 15px"
              />
            </div>
          </div>
          <div class="filter-title purple">{{'filter'|translate}}</div>
        </div>
          <ul
          *dropdownMenu
          id="dropdown-animated"
          class="dropdown-menu p-4 w-100"
          role="menu"
          [dir]="!isFlipped ? 'rtl' : 'ltr'"
          style="text-align: start;">
          <li role="menuitem" class="my-3 fs-6">
            <div class="d-flex align-items-center justify-content-between">
              <input
                type="radio"
                (click)="sortPrice($event,'asc')"
                name="price"
                id="price"
                [(ngModel)]="priceSort"
                value="asc"
              />
              <label class="hovered-pointer col-12 ms-2" style="display: flex;" for="price"> {{'lowest_price'|translate}} </label>
            </div>
          </li>
          <li role="menuitem" class="my-3 fs-6">
            <div class="d-flex align-items-center justify-content-between">
              <input
                type="radio"
                (click)="sortPrice($event,'desc')"
                name="price"
                id="h-price"
                [(ngModel)]="priceSort"
                value="desc"
              />
              <label class="hovered-pointer col-12 ms-2"  style="display: flex;" for="h-price"> {{'highest_price'|translate}} </label>
            </div>
          </li>
          <li role="menuitem" class="my-3 fs-6">
            <div class="d-flex align-items-center justify-content-between">
              <input
                type="radio"
                (change)="sortStars($event,'asc')"
                name="stars"
                id="stars"
                [(ngModel)]="starSort"
                value="asc"
              />
              <label class="hovered-pointer col-12 ms-2" style="display: flex;" for="stars"> {{'lowest_stars'|translate}} </label>
            </div>
          </li>
          <li role="menuitem" class="my-3 fs-6">
            <div class="d-flex align-items-center justify-content-between">
              <input
                type="radio"
                (change)="sortStars($event,'desc')"
                name="stars"
                id="h-stars"
                [(ngModel)]="starSort"
                value="desc"
                />
              <label class="hovered-pointer col-12 ms-2" style="display: flex;" for="h-stars"> {{'highest_stars'|translate}} </label>
            </div>
          </li>
          <li role="menuitem" class="my-3 fs-6">
            <div class="d-flex align-items-center justify-content-between">
              <input
                type="radio"
                (change)="sortRate($event,'asc')"
                name="rate"
                id="rate"
                [(ngModel)]="rateSort"
                value="asc"
              />
              <label class="hovered-pointer col-12 ms-2" style="display: flex;" for="rate"> {{'lowest_rating'|translate}} </label>
            </div>
          </li>
          <li role="menuitem" class="my-3 fs-6">
            <div class="d-flex align-items-center justify-content-between">
              <input
                type="radio"
                (change)="sortRate($event,'desc')"
                name="rate"
                id="h-rate"
                [(ngModel)]="rateSort"
                value="desc"
              />
              <label class="hovered-pointer col-12 ms-2" style="display: flex;" for="h-rate"> {{'highest_rating'|translate}} </label>
            </div>
          </li>
          <li role="menuitem" class="my-3 fs-6">
              <button class="action-button" style="font-size: 16px;" (click)="clearFilter()">
                {{'delete'|translate}}
              </button>
          </li>
        </ul>
      </div>
    </div>

  </div>
  <ng-container *ngIf="searchResult && searchResult.length > 0">
    <app-card [cardsSearch]="searchResult"></app-card>
  </ng-container>
  <ng-container *ngIf="searchResult && searchResult.length === 0">
    <p>{{'no_properties_found'|translate}}</p>
  </ng-container>

  <div class="navigation">
    <mat-icon
      *ngIf="totalPages > currentNumber"
      class="pointer"
      (click)="next()"
      >chevron_left</mat-icon
    >
    <p *ngIf="totalPages > currentNumber">{{ currentNumber + 1 }}</p>
    <div class="active">
      <p>{{ currentNumber }}</p>
    </div>
    <mat-icon
      class="pointer"
      (click)="previous()"
      [ngClass]="{ 'btn-opacity-1': currentNumber === 1 }"
      >chevron_right</mat-icon
    >
  </div>
</div>
