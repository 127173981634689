<div class="contianer container" dir="auto" style="text-align: start;">
  <div class="just flex" id="filter" title>
    <div class="arrow-flex">
      <div class="arrow-icon">
        <div class="purple-arrow">
            <img src="assets/aroovia-icons/Icon feather-arrow-left.svg" alt=""  [ngClass]="{ 'flipped-arrow': isFlipped }">
        </div>
    </div>
      <div class="title" style="text-align: start; margin-bottom: 5px;">
        {{ 'our_guests_prefer'|translate }}
      </div>
    </div>

    <div class="flex-filter flex hovered-pointer">
      <!-- <div
        class="btn-group w-100 mt-2"
        dropdown
        #dropdown="bs-dropdown"
        [insideClick]="true"
        [autoClose]="true"
      >
        <div dropdownToggle class="d-flex gap-1">
          <div class="filter-title">تصفية</div>

          <div class="filter-icon">
            <div class="purple">
              <img
                src="../../assets/aroovia-icons/Filter.svg"
                alt=""
                style="border-radius: 15px"
              />
            </div>
          </div>
        </div>
        <ul
          *dropdownMenu
          id="dropdown-animated"
          class="dropdown-menu p-4 w-100 row"
          role="menu"
        >
          <li role="menuitem" class="my-3">hello filter</li>
        </ul>
      </div> -->
    </div>
  </div>

  <ng-container *ngIf="mayLikeList" >
    <div class="cards"  dir="auto" >
      <owl-carousel-o  #owlCarousel [options]="customOptions">
        
        <ng-container  *ngFor="let card of mayLikeList" dir="auto" >
          <ng-template carouselSlide>
            <!-- <app-card [cards]="cardDetails"></app-card> -->
            <div class="mx-1" style="min-height: 300px; cursor: pointer">
              <div class="heart" (click)="onMayLike(card)">
                <div class="heart-circle">
                  <mat-icon
                    class="heart-icon heart-icon-red"
                    *ngIf="favorites.has(card.id)"
                    >favorite</mat-icon
                  >
                  <mat-icon
                    class="heart-icon heart-icon-grey"
                    *ngIf="!favorites.has(card.id)"
                    >favorite</mat-icon
                  >
                </div>
              </div>
              <div
                class="d-flex h-100"
                (click)="goToDetails(card.id)"
              >
                <img [src]="card.mainImage" alt="" style="height: 200px" />
              </div>
              <div
                class="title-rating flex"
                (click)="goToDetails(card.id)"
                role="button"
              >
                <div class="title">
                  <p [matTooltip]="card.nameAr">{{!isFlipped? card.nameAr:card.nameEn }}</p>
                </div>
                <div class="rating" *ngIf="card.averageRating > 0">
                  <p>{{ card.averageRating.toFixed(1) }}</p>
                </div>
              </div>
              <div class="location flex muted">
                <p [ngClass]="{'mt-2 mb-2':!card.website,'custom-margin':card.website}">{{ card.city }}</p>
                <div class="comma" *ngIf="card.website">
                  <p class="mb-1 mt-2">،</p>
                </div>
                <p class="custom-margin">{{ card.website }}</p>
              </div>
              <div class="rate">
                <div class="price">
                  <span class="muted modify-font ms-2">{{'starts_from'|translate}}</span>
                  <p class="number">{{ card.price | number : "1.2-2" }}</p>
                  <p class="currency">{{'ASR'|translate}}</p>
                </div>
                <div>
                  <p class="muted modify-font">{{'per_night'|translate}}</p>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>

      </owl-carousel-o>
    </div>
  </ng-container>
  <ng-container *ngIf="wislishData">
    <div class="cards"  (wheel)="onScroll($event)" dir="auto" style="text-align: start;">
      <owl-carousel-o #owlCarousel [options]="customOptions" dir="auto" style="text-align: start;">
        <ng-container *ngFor="let card of wislishData; trackBy: trackByProperty">
          <ng-template carouselSlide [id]="card.Property.email">
            <div class="mx-1" style="min-height: 280px; cursor: pointer">
              <div class="heart" (click)="onLikeProperty(card)">
                <div class="heart-circle">
                  <mat-icon
                    class="heart-icon heart-icon-red"
                    *ngIf="card.isLiked"
                    >favorite</mat-icon
                  >
                  <mat-icon
                    class="heart-icon heart-icon-grey"
                    *ngIf="!card.isLiked"
                    >favorite</mat-icon
                  >
                </div>
              </div>
              <div
                class="d-flex " style="height: 280px;"
                (click)="goToDetails(card.Property.id)"
              >
                <img [src]="card.Property.mainImage" alt="" />
              </div>
              <div
                class="title-rating flex"
                (click)="goToDetails(card.Property.id)"
                role="button"
              >
                <div class="title">
                  <p [matTooltip]="card.Property.nameAr">
                    {{!isFlipped? card.Property.nameAr:card.Property.nameEn }}
                  </p>
                </div>
                <div class="rating" *ngIf="card.Property.averageRating > 0">
                  <p>{{ card.Property.averageRating.toFixed(1) }}</p>
                </div>
              </div>
              <div class="location flex muted">
                <p [ngClass]="{'mt-2 mb-2':!card.Property.website,'custom-margin':card.Property.website}">{{ card.Property.city }}</p>
                <div class="comma" *ngIf="card.Property.website">
                  <p class="mb-1 mt-2">،</p>
                </div>
                <p class="custom-margin">{{ card.Property.website }}</p>
              </div>
              <div class="rate">
                <div class="price">
                  <span class="muted modify-font ms-2">{{'starts_from'|translate}}</span>
                  <p class="number">{{ 1000 | number : "1.2-2" }}</p>
                  <p class="currency">{{'ASR'|translate}}</p>
                </div>
                <div>
                  <p class="muted modify-font">{{'per_night'|translate}}</p>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </ng-container>

  <!-- <div class="cards mobileOnly">
      <owl-carousel-o [options]="customOptions">
         <ng-container *ngFor="let card of cardDetails">
           <ng-template carouselSlide [width]="200">
             <app-card [cards]="[card]"></app-card>
           </ng-template>
         </ng-container>
       </owl-carousel-o>
     </div> -->
</div>
