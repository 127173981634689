import { Component, OnInit } from '@angular/core';
import { OrderInterface, StatsticsDTO } from 'app/orders-table/order-interface';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { OrderDTO } from './order-dto';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-all-orders',
  templateUrl: './all-orders.component.html',
  styleUrls: ['./all-orders.component.css']
})
export class AllOrdersComponent implements OnInit {

  @BlockUI('root') rootBlocker!:NgBlockUI
  ordersList:OrderDTO[]
  statisticList:StatsticsDTO
  constructor(private datacontext:DataContextService){}

  ngOnInit(): void {
    this.gettingOrders()
  }


  private gettingOrders(){
    const token = this.datacontext.getRouteParams('token')

    // this.rootBlocker.start()
    this.datacontext.getData(`/requests/list?token=${token}`).pipe(
      catchError((err:HttpErrorResponse)=>{
        return throwError(()=>{
          // this.rootBlocker.stop()
          this.datacontext.showError('',err.error.message)
        })
      })
    ).subscribe((value:any)=>{
      if(value.success){
        this.ordersList = value.data
        this.statisticList = value.statstics
      }
      // this.rootBlocker.stop()
    })

  }

  onHandelFilterOrders(status:string){
    if(status === 'all-orders'){
      this.gettingOrders()
    }else{
      this.filterWithStatus(status)
    }
  }

  private filterWithStatus(status:string){
    const token = this.datacontext.getRouteParams('token')
    this.datacontext.getData(`/requests/list/filter?reservationStatus=${status}&token=${token}`).pipe(
      catchError((err:HttpErrorResponse)=>{
        return throwError(()=>{
          this.datacontext.showError('',err.error.message)
        })
      })
    ).subscribe((value:any) => {
      if(value.success){
        this.ordersList = value.data
      }
    })
  }

  onReloadOrders(){
    this.gettingOrders()
  }

}
