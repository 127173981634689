import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import imageCompression from 'browser-image-compression';

import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { catchError, forkJoin, throwError } from 'rxjs';
import { AmenityDTO, PropertiesTypesDTO, PropertyDTO } from './property-dto';
import { removeDialCode } from './removeCodePhone';
import {
  BedsTypesDTO,
  CancellationPolicyDTO,
} from 'app/rooms-managment/rooms-dto';
import { MatDialog } from '@angular/material/dialog';
import { CancelPolicyDialogComponent } from 'app/cancel-policy-dialog/cancel-policy-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-new-property',
  templateUrl: './new-property.component.html',
  styleUrls: ['./new-property.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NewPropertyComponent implements OnInit {
  customTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#ffffff', // Customize the background color of the container
      buttonColor: '#ef3c23', // Customize the color of the buttons
    },
    dial: {
      dialBackgroundColor: '#ef3c23', // Customize the dial's background color
    },
    clockFace: {
      clockFaceBackgroundColor: '#f1f1f1', // Customize the clock face's background
      clockHandColor: '#ef3c23', // Customize the clock hand color
      clockFaceTimeInactiveColor: '#555555', // Customize inactive time color
    },
  };
  geocoder = new google.maps.Geocoder();
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  files: any[] = [];
  options: google.maps.MapOptions = {
    mapId: 'Arrovia',
    center: { lat: 23.885942, lng: 45.079162 },
    zoom: 8,
  };
  markerPosition: google.maps.LatLngLiteral = {
    lat: 23.885942,
    lng: 45.079162,
  };
  locationName: string = '';
  onChooseLocation(event: google.maps.MapMouseEvent) {
    if (event.latLng !== null) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.getLocationName(this.markerPosition.lat, this.markerPosition.lng);
    }
  }
  getLocationName(lat: number, lng: number) {
    const latlng = {
      lat: lat,
      lng: lng,
    };

    this.geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results && results[0]) {
          const locationName = results[0].formatted_address; // Get the formatted address

          // Update the Address field in addressDetails form
          this.addressDetails.patchValue({
            Address: locationName,
          });
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ', status);
      }
    });
  }
  formSubmitted = false;
  @ViewChild('uploadImageCloud') lableCloudUpload: ElementRef<HTMLDivElement>;
  async onSelect(event: any): Promise<void> {
    const addedFiles = event.addedFiles;
    const compressedFiles = await Promise.all(
      addedFiles.map((file: any) => this.compressImage(file))
    );
    this.files.push(...compressedFiles);
    this.files.forEach((file) => {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          file.preview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    });
    if (this.lableCloudUpload) {
      this.lableCloudUpload.nativeElement.style.opacity = '0';
    }
  }
  async compressImage(file: File): Promise<File> {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      initialQuality: 0.7,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      console.log(
        'compressedFile instanceof Blob',
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      return file; // Return the original file if compression fails
    }
  }
  onRemove(event: any, clickEvent: Event): void {
    clickEvent.stopPropagation(); // Stop the event propagation

    this.files.splice(this.files.indexOf(event), 1);
    const token = this.datacontext.getRouteParams('token');

    this.datacontext
      .postData(`/property/remove-image?token=${token}`, {
        id: this.queryId,
        name: event.replace('https://api.aroovia.com/api/uploads?path=', ''),
      })
      .subscribe((res) => console.log(res));

    if (this.files.length === 0) {
      this.lableCloudUpload.nativeElement.style.opacity = '1';
    }
  }
  personalDetails!: FormGroup;
  addressDetails!: FormGroup;
  educationalDetails!: FormGroup;
  personal_step = false;
  address_step = false;
  education_step = false;
  step = 1;
  propertyTypeList: PropertyDTO[];
  taxNumImageUrl: string | ArrayBuffer | null | undefined = null;
  starsPaperImageUrl: string | undefined = '';
  liscenceImageUrl: string | ArrayBuffer | null | undefined = null;
  logoImageUrl: string | ArrayBuffer | null | undefined = null;
  mainPicImageUrl: string | ArrayBuffer | null | undefined = null;
  cancellationPolicy: CancellationPolicyDTO[];
  bedsTypes: BedsTypesDTO[];
  filesInEdit: string[] = [];
  ownerIdentityImageUrl: string | ArrayBuffer | null | undefined = null;
  step1FieldValues: any;
  isFlipped = false;
  private getPropertyRooms() {
    // this.rootBlocker.start();
    const token = this.datacontext.getRouteParams('token');
    const functions = [
      this.datacontext.getData(`/property/list?token=${token}`),
      this.datacontext.getData(`/room/list-rooms?token=${token}`),
      this.datacontext.getData('/cancellation-policy/list'),
    ];
    forkJoin(functions)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe({
        next: (value: any) => {
          if (value[0].success) {
            this.propertyTypeList = value[0].data.map((prop: any) => {
              return {
                id: prop.id,
                nameAr: prop.nameAr,
              };
            });
          }

          if (value[1].success) {
            this.bedsTypes = value[1].data;
          }
          if (value[2].success) {
            this.cancellationPolicy = value[2].data;
            // this.roomsInformationForm
            //   .get('refuse1')
            //   ?.setValue(this.cancellationPolicy[0].id);
          }
          // this.rootBlocker.stop();
        },
      });
  }
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datacontext: DataContextService,
    private ngzone: NgZone,
    private ch: ChangeDetectorRef,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private el: ElementRef
  ) {}



  form2 = {
    nameEnglishLabel: 'Name in English',
    nameArabicLabel: 'اسم العقار باللغة العربية ',
    descArabicLabel: 'الوصف باللغة العربية',
    descEnglishLabel: 'Description in English',
  };

  isEdit: boolean = false;
  queryId: string = '';
  ngOnInit() {
    this.isFlipped = this.translate.currentLang === 'en';
    this.getPropertyRooms();

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    this.personalDetails = this.formBuilder.group({
      rooms: ['', Validators.required],
      guests: ['', Validators.required],
      beds: ['', Validators.required],
      bathrooms: ['', Validators.required],
      totalSpace: ['', Validators.required],
    });

    // Initial log when the component is initialized
    console.log(this.personalDetails.invalid);
    //check if he is add or edit
    this.activatedRoute.queryParams.subscribe((query) => {
      const isAdd = query['new'];
      if (isAdd == '0') {
      } else if (isAdd == '1' && query['id']) {
        this.isEdit = true;
        const queryId = query['id'];
        this.queryId = queryId;
        this.getPropertyOnEdit(queryId);
      } else {
        this.router.navigate(['/profile', 'property-manage']);
      }
    });
    this.initPersonDetailsForm();
    this.initAddressDetailsForm();
    this.getAllAmenity();
  }
  viewCancellationPolicy(cancel: CancellationPolicyDTO) {
    this.dialog.open(CancelPolicyDialogComponent, {
      data: cancel,
      width: '500px',
      // maxHeight:'400px'
    });
  }

  private initPersonDetailsForm() {
    this.personalDetails = this.formBuilder.group({
      type: ['', [Validators.required, Validators.maxLength(50)]],
      hotelStars: [''], //hotel validation
      taxNum: [''],
      ownerName: ['', [Validators.required, Validators.maxLength(100)]],
      ownerId: ['', [Validators.required, Validators.min(10)]],
      priceBefore: ['', [Validators.required]],
      rooms: ['', [Validators.required]],
      guests: ['', [Validators.required]],
      beds: ['', [Validators.required]],
      bathrooms: ['', [Validators.required]],
      totalSpace: ['', [Validators.required]],
      priceAfter: [''],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(10),
          Validators.pattern(/^(0[0-9]{9}|[1-9][0-9]{8}|5[0-9]{9})$/), 
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      checkInTime: [''],
      CancellationPolicyId: ['', [Validators.required]],
      checkOutTime: [''],
      isInsured: [false],
      insuranceValue: [''],
      isAutoAccepted: [false],
    });
  }

  private initAddressDetailsForm() {
    this.addressDetails = this.formBuilder.group({
      NameEn: [''],
      NameAr: ['', Validators.required],
      descriptionAr: [
        '',
        [Validators.maxLength(700), Validators.minLength(100)],
      ],
      descriptionEn: [''],
      Location: [''],
      Email: [''],

      PhoneNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(10),
          Validators.pattern(/^(0[0-9]{9}|[1-9][0-9]{8}|5[0-9]{9})$/), 
        ],
      ],

      Address: ['', Validators.required],
      phone2: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(10),
          Validators.pattern(/^(0[0-9]{9}|[1-9][0-9]{8}|5[0-9]{9})$/), 
        ],
      ],
      email2: ['', Validators.required],
      city: ['', Validators.required],
    });
  }

  // Custom validator function
  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) {
        return null; // Don't validate empty values to allow optional controls
      }
      const valid = /^(0\d{9}|\d{9})$/.test(value);
      return valid ? null : { invalidPhoneNumber: { value: control.value } };
    };
  }

  addInsurance(e: Event) {
    const isChecked = (e.target as HTMLInputElement).checked;
    if (isChecked) {
      this.personalDetails.controls['insuranceValue'].addValidators(
        Validators.required
      );
    } else {
      this.personalDetails.controls['insuranceValue'].clearValidators();
      this.personalDetails.controls['insuranceValue'].updateValueAndValidity();
    }
    this.ch.detectChanges();
  }

  googleAutoComplete: google.maps.places.Autocomplete | undefined;
  @ViewChild('autoComplete') inputRef: ElementRef<HTMLInputElement>;

  private initGoogleMap() {
    if (this.inputRef) {
      const options = {
        componentRestrictions: { country: 'SA' }, // Restrict to Saudi Arabia
      };

      this.googleAutoComplete = new google.maps.places.Autocomplete(
        this.inputRef.nativeElement,
        options
      );

      this.googleAutoComplete.addListener('place_changed', () => {
        const place = this.googleAutoComplete?.getPlace();
        const lat = place?.geometry?.location?.lat()!;
        const lng = place?.geometry?.location?.lng()!;
        this.markerPosition = { lat: lat, lng: lng } as any;
        this.ngzone.run(() => {
          this.options = {
            ...this.options,
            center: this.markerPosition,
          };
          setTimeout(() => {
            console.clear(); // Just for clearing the console errors
          }, 2000);
        });
        this.addressDetails.controls['Address'].setValue(
          place?.formatted_address
        );
      });
    }
  }

  propertyOnEdit: PropertyDTO;
  private getPropertyOnEdit(id: string) {
    // this.rootBlocker.start();
    const token = this.datacontext.getRouteParams('token');
    this.datacontext
      .getData(`/property/${id}?token=${token}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe({
        next: (value: any) => {
          if (value.success) {
            this.restoreFormsOnEdit(value.data);
            this.propertyOnEdit = value.data;
          }
          // this.rootBlocker.stop();
        },
      });
  }
  getFileSrc(file: any): string {
    // Add logic to determine if the file.preview is a URL or a file path
    console.log(file);
    console.log(typeof file);
    if (typeof file !== 'object') {
      console.log('hey');
      return file;
    }
    console.log('hey');
    return URL.createObjectURL(file); // Assuming file.preview is a Blob or File object
  }
  private async restoreFormsOnEdit(data: PropertyDTO) {
    this.personalDetails.get('type')?.setValue(data.type);
    this.taxNumImageUrl = data.commercialRecord;
    console.log(data);
    let imgs = data.images.split(',');
    imgs = imgs.map((img: any) => {
      return 'https://api.aroovia.com/api/uploads?path=' + img;
    });
    this.filesInEdit = imgs;
    const filePromises = imgs.map(async (imgUrl: string) => {
      return await fetch(imgUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File(
            [blob],
            imgUrl.split('/').pop() || 'image.jpg',
            { type: blob.type }
          );
          const safeUrl = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(file)
          );

          return file;
        })
        .catch((error) => {
          console.error('Error fetching image:', error);
          return null;
        });
    });

    const files = await Promise.all(filePromises);
    this.files = imgs;
    // this.files = files.filter((file) => file !== null);

    console.log(this.files);
    this.personalDetails.get('taxNum')?.setValue(data.taxNumber);
    this.personalDetails
      .get('CancellationPolicyId')
      ?.setValue(data.Rooms[0]?.CancellationPolicyId);
    if (this.personalDetails.value.type == 1) {
      this.personalDetails.get('hotelStars')?.setValue(data.hotelStars);
      this.starsPaperImageUrl = data.hotelStartsCertificate;
      this.onChangeRealStateType();
    }
    this.personalDetails.get('rooms')?.setValue(data.rooms);
    this.personalDetails.get('guests')?.setValue(data.guests);
    this.personalDetails.get('beds')?.setValue(data.beds);
    this.personalDetails.get('bathrooms')?.setValue(data.bathrooms);
    this.personalDetails.get('totalSpace')?.setValue(data.totalSpace);
    this.personalDetails
      .get('insuranceValue')
      ?.setValue(data.insuranceValue ? data.insuranceValue : false);
    this.personalDetails.get('isAutoAccepted')?.setValue(data.isAutoAccepted);
    this.personalDetails
      .get('isInsured')
      ?.setValue(data.isInsured ? data.isInsured : false);
    this.liscenceImageUrl = data.tourismLicense;
    // this.ownerIdentityImageUrl = data.ownerId;
    this.personalDetails.get('ownerId')?.setValue(data.ownerId);
    this.personalDetails.get('priceAfter')?.setValue(data.priceAfterDiscount);
    this.personalDetails.get('priceBefore')?.setValue(data.price);
    this.personalDetails.get('ownerName')?.setValue(data.ownerName);
    this.personalDetails
      .get('phone')
      ?.setValue(removeDialCode(data.privateMobile));
    this.personalDetails.get('email')?.setValue(data.privateEmail);

    this.addressDetails.get('NameEn')?.setValue(data.nameEn ? data.nameEn : '');
    this.addressDetails.get('NameAr')?.setValue(data.nameAr);
    this.addressDetails
      .get('descriptionEn')
      ?.setValue(data.discEn ? data.discEn : '');
    this.addressDetails
      .get('descriptionAr')
      ?.setValue(data.discAr ? data.discAr : '');
    this.mainPicImageUrl = data.mainImage;
    this.logoImageUrl = data.logo;
    this.addressDetails
      .get('PhoneNumber')
      ?.setValue(removeDialCode(data.privateMobile.replace('+966', '')));
    this.addressDetails.get('city')?.setValue(data.city);

    this.addressDetails.get('email2')?.setValue(data.email);
    this.addressDetails
      .get('phone2')
      ?.setValue(data.mobile.replace('+966', ''));
    this.addressDetails
      .get('Location')
      ?.setValue(data.website ? data.website : '');
    this.addressDetails.get('Address')?.setValue(data.address);
    if (data.mapLon && data.mapLat) {
      this.markerPosition = { lat: +data.mapLat, lng: +data.mapLon };
      this.options = {
        mapId: 'Arrovia',
        center: { lat: +data.mapLat, lng: +data.mapLon },
        zoom: 8,
      };
    }
    this.amenityChoosenList = data.PropertyAmenities.map(
      (prop) => prop.AmenityId
    );
    this.amenitySpecialChoosenList = data.PropertySpecialAmenities.map(
      (prop) => prop.AmenityId
    );
  }

  privateNumberValue: any;
  private removeCountryCode(phoneNumber: string) {
    const regex = /^(?:\+|00)(\d{1,3})(\d{7,})$/;
    const match = phoneNumber.match(regex);

    if (match) {
      return match[2];
    }
    return phoneNumber; // Return the original if no match is found
  }

  get personal() {
    return this.personalDetails.controls;
  }
  get address() {
    return this.addressDetails.controls;
  }
  get education() {
    return this.educationalDetails.controls;
  }
  onChangeRealStateType() {
    const propertyType = this.personalDetails.get('type')?.value;
    if (propertyType == '1') {
      this.personalDetails
        .get('propertyName')
        ?.addValidators([Validators.required]);
      this.personalDetails
        .get('hotelStars')
        ?.addValidators(Validators.required);
      this.personalDetails.updateValueAndValidity();
      this.addressDetails.get('Email')?.addValidators(Validators.required);
      this.addressDetails
        .get('PhoneNumber')
        ?.addValidators(Validators.required);
      // this.addressDetails.get('PhoneNumber')?.updateValueAndValidity()
      // this.addressDetails.get('Email')?.updateValueAndValidity()
    } else {
      this.personalDetails
        .get('propertyName')
        ?.removeValidators([Validators.required]);
      this.personalDetails
        .get('hotelStars')
        ?.removeValidators(Validators.required);
      this.personalDetails.get('hotelStars')?.updateValueAndValidity();
      this.addressDetails.get('Email')?.removeValidators(Validators.required);
      this.addressDetails
        .get('PhoneNumber')
        ?.removeValidators(Validators.required);
      this.addressDetails.get('PhoneNumber')?.updateValueAndValidity();
      this.addressDetails.get('Email')?.updateValueAndValidity();
    }
  }
  next() {
    if (this.step === 1) {
      if (this.personalDetails.invalid) {
        this.datacontext.showError(
          this.translate.instant('errorr'),
          this.translate.instant('incomplete_information')
        );
        return;
      }
      this.personal_step = true;
      console.log(this?.privateNumberInput);
      console.log(this.privateNumberInput?.phoneNumber);
      // this.privateNumberValue = this.privateNumberInput.phoneNumber;
      this.step++;
      this.ch.detectChanges();
      this.initGoogleMap();
      window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
    } else if (this.step === 2) {
      if (this.addressDetails.invalid) {
        this.datacontext.showError(
          '',
          this.translate.instant('incorrect_data')
        );
        return;
      }

      if (this.files.length < 4 && !this.isEdit) {
        this.datacontext.showError(
          this.translate.instant('please_make_sure_to_add_property_images'),
          ''
        );

        return;
      }
      this.onSubmitProperty();

      // this.address_step = true;
      // this.step++;
    }
  }
  disabled: boolean = false;
  propertyUpdatedId: number = 0;
  private onSubmitProperty() {
    const token = this.datacontext.getRouteParams('token');
    this.disabled = true;
    let url;
    if (this.isEdit) {
      url = `/property/update?token=${token}`;
    } else {
      url = `/property/create?token=${token}`;
    }
    const valueFormData = this.prepareFormData();
    // this.rootBlocker.start();
    this.datacontext
      .postData(url, valueFormData)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            this.disabled = false;

            this.datacontext.showError(
              '',
              this.translate.instant(`${err.error.message}`)
            );
            console.log(err.error.message);
          });
        })
      )
      .subscribe({
        next: (value: any) => {
          if (value.success) {
            this.propertyUpdatedId = value.data.id;

            if (!this.isEdit) {
              this.datacontext.showSuccess(
                '',
                this.translate.instant('property_added_successfully')
              );
            } else {
              this.datacontext.showSuccess(
                '',
                this.translate.instant('property_updated_successfully')
              );
            }
            this.step++;
          }
          // this.rootBlocker.stop();
        },
      });
  }

  @ViewChild('privateNumber') privateNumberInput: any;
  @ViewChild('mobileNumber') mobileNumber: any;
  private prepareFormData(): FormData {
    let formData = new FormData();
    if (this.isEdit) {
      console.log('first');
      this.activatedRoute.queryParamMap.subscribe((params) => {
        formData.append('id', params.get('id') as any);
        console.log('ID:', params.get('id')); // Logs '58' in this case
      });
      console.log('first');
    }

    formData.append(
      'isInsured',
      this.personalDetails.value.isInsured.toString()
    );
    console.log('first');
    formData.append('checkInTime', this.personalDetails.value.checkInTime);
    formData.append('checkOutTime', this.personalDetails.value.checkOutTime);
    formData.append(
      'CancellationPolicyId',
      this.personalDetails.value.CancellationPolicyId.toString()
    );
    console.log('first');
    formData.append(
      'insuranceValue',
      this.personalDetails.value.insuranceValue.toString()
    );
    formData.append('rooms', this.personalDetails.value.rooms.toString());
    formData.append('guests', this.personalDetails.value.guests.toString());
    formData.append('beds', this.personalDetails.value.beds.toString());
    formData.append(
      'bathrooms',
      this.personalDetails.value.bathrooms.toString()
    );
    formData.append(
      'totalSpace',
      this.personalDetails.value.totalSpace.toString()
    );
    formData.append('city', this.addressDetails.value.city.toString());
    formData.append('type', this.personalDetails.value.type);
    formData.append(
      'isAutoAccepted',
      this.personalDetails.value.isAutoAccepted
    );

    if (this.commercialRecordImage) {
      formData.append('commercialRecord', this.commercialRecordImage);
    }
    formData.append('taxNumber', this.personalDetails.value.taxNum);
    if (this.personalDetails.value.type == '1') {
      formData.append(
        'hotelStars',
        this.personalDetails.value.hotelStars.toString()
      );
      if (this.hotelStartsCertificate) {
        formData.append('hotelStartsCertificate', this.hotelStartsCertificate);
      }
    }
    if (this.tourismLicense) {
      formData.append('tourismLicense', this.tourismLicense);
    }
    formData.append('ownerId', this.personalDetails.value.ownerId);
    formData.append('price', this.personalDetails.value.priceBefore);
    formData.append(
      'priceAfterDiscount',
      this.personalDetails.value.priceAfter || ''
    );

    formData.append('ownerName', this.personalDetails.value.ownerName);
    this.ch.detectChanges();
    console.log(this.addressDetails.value);
    formData.append('privateMobile', '+966' + this.personalDetails.value.phone);
    formData.append('privateEmail', this.personalDetails.value.email);
    formData.append('nameEn', this.addressDetails.value.NameEn);
    formData.append('nameAr', this.addressDetails.value.NameAr);
    formData.append('discEn', this.addressDetails.value.descriptionEn);
    formData.append('discAr', this.addressDetails.value.descriptionAr);
    formData.append('mobile', '+966' + this.addressDetails.value.phone2);

    formData.append('email', this.addressDetails.value.email2);
    if (this.mainImage) {
      formData.append('mainImage', this.mainImage);
    }
    if (this.files.length > 0) {
      this.files.forEach((file) => {
        if (typeof file === 'object') {
          formData.append('images', file);
        }
      });
    }

    if (this.logo) {
      formData.append('logo', this.logo);
    }
    if (this.personalDetails.get('type')?.value == '1') {
      formData.append(
        'mobile',
        this.addressDetails.value.PhoneNumber.dialCode +
          this.mobileNumber.phoneNumber
      );
      formData.append('email', this.addressDetails.value.Email);
    }
    formData.append('website', this.addressDetails.value.Location);
    formData.append('address', this.addressDetails.value.Address);
    this.amenitySpecialChoosenList.forEach((item) => {
      formData.append('specialAmenities', item.toString());
    });
    this.amenityChoosenList.forEach((item) => {
      formData.append('amenities', item.toString());
    });

    formData.append('mapLon', this.markerPosition.lng.toString());
    formData.append('mapLat', this.markerPosition.lat.toString());
    return formData;
  }

  previous() {
    this.step--;

    if (this.step === 1) {
      this.address_step = false;
      // Set values of step 1 fields back to the form controls
      this.personalDetails.patchValue(this.step1FieldValues);
    } else if (this.step === 2) {
      this.education_step = false;
    } else if (this.step === 3) {
      this.education_step = false;
    }
    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
  }

  submit() {
    if (this.step === 3) {
      this.education_step = true;
      if (this.educationalDetails.invalid) {
        return;
      }
      alert('Well done!!');
      ('');
    }
  }

  logo: any;
  onLogoImageChange(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    this.logoImageUrl = file?.name;
    this.logo = file;
  }
  mainImage: any;
  onMainPicImageChange(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    this.mainPicImageUrl = file?.name;
    this.mainImage = file;
  }
  commercialRecordImage: any;
  onTaxNumImageChange(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    this.taxNumImageUrl = file?.name;
    this.commercialRecordImage = file;
  }
  tourismLicense: any;
  onLicenseImageChange(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    this.liscenceImageUrl = file?.name;
    this.tourismLicense = file;
  }

  hotelStartsCertificate: any;
  onStarsPaperImageChange(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    this.starsPaperImageUrl = file?.name;
    this.hotelStartsCertificate = file;
  }
  ownerId: any;
  onOwnerIdentityImageChange(event: any): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    this.ownerIdentityImageUrl = file?.name;
    this.ownerId = file;
  }

  openTaxNumFileDialog(): void {
    const inputElement = document.getElementById('taxNum');
    inputElement?.click();
  }

  openMainPicFileDialog(): void {
    const inputElement = document.getElementById('main-pic');
    inputElement?.click();
  }
  openLogoFileDialog(): void {
    const inputElement = document.getElementById('logo');
    inputElement?.click();
  }
  openLicenseFileDialog(): void {
    const inputElement = document.getElementById('license');
    inputElement?.click();
  }

  openStarsPaperFileDialog(): void {
    const inputElement = document.getElementById('starsPaper');
    inputElement?.click();
  }

  openOwnerIdentityFileDialog(): void {
    const inputElement = document.getElementById('ownerIdentity');
    inputElement?.click();
  }

  uploadTaxNumImage(): void {
    const taxNumControl = this.personalDetails.get('taxNum');
    const file = taxNumControl?.value as File | undefined;

    if (!file) {
      return; // Don't proceed if the field is undefined
    }

    // Implement your image upload logic here, e.g., send the file to a server

    // Reset the file input and imageUrl after successful upload
    this.taxNumImageUrl = null;
    taxNumControl?.setValue(null);
  }
  uploadMainPicImage(): void {
    const mainPicControl = this.personalDetails.get('mainPic');
    const file = mainPicControl?.value as File | undefined;

    if (!file) {
      return; // Don't proceed if the field is undefined
    }

    // Implement your image upload logic here, e.g., send the file to a server

    // Reset the file input and imageUrl after successful upload
    this.mainPicImageUrl = null;
    mainPicControl?.setValue(null);
  }
  uploadLiscenceImage(): void {
    const liscenceControl = this.personalDetails.get('liscence');
    const file = liscenceControl?.value as File | undefined;

    if (!file) {
      return; // Don't proceed if the field is undefined
    }

    // Implement your image upload logic here, e.g., send the file to a server

    // Reset the file input and imageUrl after successful upload
    this.liscenceImageUrl = null;
    liscenceControl?.setValue(null);
  }

  uploadLogoImage(): void {
    const logoControl = this.personalDetails.get('logo');
    const file = logoControl?.value as File | undefined;

    if (!file) {
      return; // Don't proceed if the field is undefined
    }

    // Implement your image upload logic here, e.g., send the file to a server

    // Reset the file input and imageUrl after successful upload
    this.logoImageUrl = null;
    logoControl?.setValue(null);
  }
  uploadStarsPaperImage(): void {
    const starsPaperControl = this.personalDetails.get('starsPaper');
    const file = starsPaperControl?.value as File | undefined;

    if (!file) {
      return; // Don't proceed if the field is undefined
    }

    // Implement your image upload logic here, e.g., send the file to a server

    // Reset the file input and imageUrl after successful upload
    // this.starsPaperImageUrl = null;
    starsPaperControl?.setValue(null);
  }

  uploadOwnerIdentityImage(): void {
    const ownerIdentityControl = this.personalDetails.get('ownerIdentity');
    const file = ownerIdentityControl?.value as File | undefined;

    if (!file) {
      return; // Don't proceed if the field is undefined
    }

    // Implement your image upload logic here, e.g., send the file to a server

    // Reset the file input and imageUrl after successful upload
    this.ownerIdentityImageUrl = null;
    ownerIdentityControl?.setValue(null);
  }
  @BlockUI('root') rootBlocker!: NgBlockUI;

  amenityList: AmenityDTO[];
  propertiesTypes: PropertiesTypesDTO[];
  private getAllAmenity() {
    const functions = [
      this.datacontext.getData('/amenity/list'),
      this.datacontext.getData('/property/property-types'),
    ];
    // this.rootBlocker.start();
    forkJoin(functions)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe({
        next: (value: any) => {
          if (value[0].success) {
            this.amenityList = value[0].data;
          }
          if (value[1].success) {
            this.propertiesTypes = value[1].data;
          }
          // this.rootBlocker.stop();
        },
      });
  }

  amenityChoosenList: number[] = [];
  amenitySpecialChoosenList: number[] = [];
  onCheckAmenity(e: Event) {
    let isChecked = (e.target as HTMLInputElement).checked;
    const value = (e.target as HTMLInputElement).value;
    if (isChecked) {
      console.log(this.amenityChoosenList);
      if (this.amenityChoosenList.length === 3) {
        (e.target as HTMLInputElement).checked = false;
        return this.datacontext.showError('', 'لا يمكن الاختيار اكثر من 3');
      }
      this.amenityChoosenList.push(parseInt(value));
    } else {
      const removeIdIndex = this.amenityChoosenList.findIndex(
        (id) => id === parseInt(value)
      );
      if (removeIdIndex !== -1) {
        this.amenityChoosenList.splice(removeIdIndex, 1);
      }
    }
  }

  onCheckAmenitySpecial(e: Event) {
    const isChecked = (e.target as HTMLInputElement).checked;
    const value = (e.target as HTMLInputElement).value;
    if (isChecked) {
      this.amenitySpecialChoosenList.push(parseInt(value));
    } else {
      const removeIdIndex = this.amenitySpecialChoosenList.findIndex(
        (id) => id === parseInt(value)
      );
      if (removeIdIndex !== -1) {
        this.amenitySpecialChoosenList.splice(removeIdIndex, 1);
      }
    }
  }

  filterInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const filteredValue = inputElement.value.replace(/[^\u0600-\u06FFA-Za-z0-9]/g, '');
    inputElement.value = filteredValue;
  }
  filterInputEN(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const filteredValue = inputElement.value.replace(/[^A-Za-z0-9]/g, '');
    inputElement.value = filteredValue;
  }
  
}
