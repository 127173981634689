import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RoomsDTO } from 'app/rooms-managment/rooms-dto';
import { SearchDTO } from 'app/search-details/search-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-room-options',
  templateUrl: './room-options.component.html',
  styleUrls: ['./room-options.component.css'],
})
export class RoomOptionsComponent implements OnInit {
  isCompletedReservation: boolean = false;
  @Input() hotelDetails: SearchDTO | null = null;
  @Input() choosenData: any;
  @Input() sharedNetPrice_BE: number;
  @Output() netPriceChange: EventEmitter<number> = new EventEmitter();
  @BlockUI('root') rootBlocker!: NgBlockUI;
  constructor(
    private router: Router,
    private datacontext: DataContextService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {}

  totalPrice: number = 0;
  netPrice_BE = 0;

  ngOnInit(): void {}
  incrementGuests(room: RoomsDTO) {
    const searchTempData = JSON.parse(localStorage.getItem('searchResult')!);
    if (!this.datacontext.userData) {
      this.datacontext.showError('', this.translate.instant('please_register'));
      this.router.navigate(['/signin']).then(() => {
        window.scrollTo(0, 0);
      });
      return;
    }
    if (!searchTempData) {
      this.datacontext.showError('',this.translate.instant('Select date and guests'));
      return;
    }

    if (room.roomsCount === room.roomCount)
      return this.datacontext.showError(
        '',
        this.translate.instant('room_not_available')
      );

    this.addToCart(room);
  }
  decrementGuests(room: RoomsDTO) {
    const searchTempData = JSON.parse(localStorage.getItem('searchResult')!);
    if (!this.datacontext.userData) {
      this.datacontext.showError('', this.translate.instant('please_register'));
      this.router.navigate(['/signin']).then(() => {
        window.scrollTo(0, 0);
      });
      return;
    }
    if (!searchTempData)
      return this.datacontext.showError('',this.translate.instant('Select date and guests'));
    if (room.roomCount != 0) {
      this.updateTotalPriceInDecrement(room);
    }
  }
  private changeNetPriceBE(constReservtionData: {
    property: SearchDTO;
    children: any;
    adults: any;
    infants: any;
    startDate: any;
    endDate: any;
    propertyId: number;
  }) {
    const token = this.datacontext.getRouteParams('token');
    const UserInfo = JSON.parse(localStorage.getItem('userInfo')!);
    const reserveData = {
      PropertyId: constReservtionData.property.id,
      email: UserInfo.email,
      rooms: constReservtionData.property.Rooms.map((room: any) => {
        return { id: room.id, quantity: 1 };
      }),
      // copoun:this.copun,
      startDate: constReservtionData.startDate,
      endDate: constReservtionData.endDate,
      adults: constReservtionData.adults,
      infants: constReservtionData.infants,
      childerns: constReservtionData.children,
      firstName: UserInfo.firstName,
      lastName: UserInfo.lastName,
      mobile: UserInfo.mobile,
    };
    this.datacontext
      .postData(`/kamel/cost?token=${token}`, reserveData)
      .subscribe({
        next: (response: any) => {
          let data = response.data;
          this.netPrice_BE = data.netPrice;
          // this.sharedNetPrice_BE = data.price;
          this.netPriceChange.emit(data.netPrice);
          // this.totalPriceAfterDisc_BE = data.priceAfterDiscount;
          // this.copunPrice_BE = data.copounDiscount;
          // this.totalPriceAfterVat_BE = data.priceAfterVat;
          // this.netPrice_BE = data.netPrice;
          console.log(data);
          localStorage.setItem('reserveData', JSON.stringify(data));
        },
        error: (err: HttpErrorResponse) => {
          this.datacontext.showError('', err.error.message);
        },
        complete: () => {},
      });
  }
  private updateTotalPriceInDecrement(room: RoomsDTO) {
    const reservationTemp = JSON.parse(localStorage.getItem('searchResult')!);
    const reservationData = {
      room: room,
    };
    const constReservationData = {
      property: this.hotelDetails!,
      children: reservationTemp.children,
      adults: reservationTemp.adults,
      infants: reservationTemp.infants,
      startDate: reservationTemp.startDate,
      endDate: reservationTemp.endDate,
      propertyId: this.hotelDetails?.id!,
    };
    const findRoomIndex = this.reservationRoomTempData.findIndex(
      (x: any) => x.room.id === room.id
    );
    this.reservationRoomTempData[findRoomIndex].room.roomCount -= 1;
    if (this.reservationRoomTempData[findRoomIndex].room.roomCount === 0) {
      this.reservationRoomTempData.splice(findRoomIndex, 1);
    }

    this.totalPrice -= room.price;

    localStorage.setItem(
      'constReservtionData',
      JSON.stringify(constReservationData)
    );
    localStorage.setItem(
      'roomsDetails',
      JSON.stringify(this.reservationRoomTempData)
    );

    this.changeNetPriceBE(constReservationData);
  }

  private calcTotalPrice(room: RoomsDTO) {
    this.totalPrice += 1;
    console.log(room.price);
  }

  reservationRoomTempData: any = [];
  addToCart(room: RoomsDTO) {
    const isAuth = this.datacontext.userData ? true : null;
    if (isAuth) {
      //check if all data is valid
      const reservationTemp = JSON.parse(localStorage.getItem('searchResult')!);
      if (reservationTemp) {
        // checking the avalibilty
        this.onCheckValidity(room);
      } else {
        this.datacontext.showError(
          '',
          this.translate.instant('please_select_date_and_guests')
        );
        const resDiv = document.getElementById('reservationId');
        resDiv?.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      this.datacontext.showError('', this.translate.instant('please_register'));
      this.router.navigate(['/signin']).then(() => {
        window.scrollTo(0, 0);
      });
    }
  }

  handelReservation() {
    if (this.totalPrice === 0) {
      this.datacontext.showError(
        '',
        this.translate.instant('you_must_select_a_room')
      );
      return;
    }
    let res1 = this.reservationRoomTempData;
    let res2 = JSON.parse(localStorage.getItem('userInfo')!);
    localStorage.setItem(
      'reservationDetails',
      JSON.stringify({ ...res1, ...res2 })
    );
    this.router.navigate(['/checkout']);
  }

  isAvaliable: boolean = true;
  onCheckValidity(room: RoomsDTO) {
    const searchTempData = JSON.parse(localStorage.getItem('searchResult')!);
    if (room && room.roomCount >= 0 && searchTempData) {
      // check validity
      const checkAvailability = {
        startDate: searchTempData.startDate,
        endDate: searchTempData.endDate,
        roomId: room.id,
        roomCount: 1,
      };
      // this.rootBlocker.start();
      this.datacontext
        .postData('/reservation/check-availability', checkAvailability)
        .subscribe((value: any) => {
          if (value.isRoomAvailable) {
            room.roomCount++;
            this.prepareReservation(room);
          } else {
            this.isAvaliable = false;
            this.datacontext.showError(
              '',
              this.translate.instant('room_not_available')
            );
          }
          // this.rootBlocker.stop();
        });
    }
  }

  private prepareReservation(room: RoomsDTO) {
    const reservationTemp = JSON.parse(localStorage.getItem('searchResult')!);
    const reservationData = {
      room: room,
    };
    const constReservationData = {
      property: this.hotelDetails!,
      children: reservationTemp.children,
      adults: reservationTemp.adults,
      infants: reservationTemp.infants,
      startDate: reservationTemp.startDate,
      endDate: reservationTemp.endDate,
      propertyId: this.hotelDetails?.id!,
    };
    const findRoomIndex = this.reservationRoomTempData.findIndex(
      (x: any) => x.room.id === room.id
    );
    if (findRoomIndex !== -1) {
      // this.reservationRoomTempData[findRoomIndex].room.roomCount += 1;
      // this.guestsNumber += 1;
    } else {
      this.reservationRoomTempData.push(reservationData);
      // this.datacontext.showSuccess('', 'تم الاضافة')
    }
    this.calcTotalPrice(room);
    console.log(constReservationData);
    localStorage.setItem(
      'constReservtionData',
      JSON.stringify(constReservationData)
    );
    console.log(this.reservationRoomTempData);
    localStorage.setItem(
      'roomsDetails',
      JSON.stringify(this.reservationRoomTempData)
    );
    this.changeNetPriceBE(constReservationData);
  }
  getTotalSize(): number {
    return (
      this.hotelDetails?.Rooms.reduce(
        (totalSize: number, room: any) => totalSize + room.size,
        0
      ) || 0
    );
  }
  confirm() {
    //step 1 check availabiliy
    this.isAvaliable=true
    const searchTempData = JSON.parse(localStorage.getItem('searchResult')!);
    if (!this.datacontext.userData) {
      this.datacontext.showError('', this.translate.instant('please_register'));
      this.router.navigate(['/signin']).then(() => {
        window.scrollTo(0, 0);
      });
      return;
    }
    if (!searchTempData) {
      this.datacontext.showError('', this.translate.instant('Select date and guests'));
      return;
    }
    //step 2 add all rooms to the cart and change price
    if (
      this.hotelDetails?.guests &&
      this.hotelDetails.guests < searchTempData.adults + searchTempData.children
    ) {
      this.datacontext.showError(
        '',
        this.translate.instant('number_of_guests_more_than_available')
      );
      return;
    }
    const reservationTemp = JSON.parse(localStorage.getItem('searchResult')!);

    const rooms = this.hotelDetails?.Rooms;
    if (rooms && rooms.length) {
      this.onCheckValidity({ ...rooms[0], roomCount: 1 });
    }
    const constReservationData = {
      property: this.hotelDetails!,
      children: reservationTemp.children,
      adults: reservationTemp.adults,
      infants: reservationTemp.infants,
      startDate: reservationTemp.startDate,
      endDate: reservationTemp.endDate,
      propertyId: this.hotelDetails?.id!,
    };

    localStorage.setItem(
      'constReservtionData',
      JSON.stringify(constReservationData)
    );

    localStorage.setItem(
      'roomsDetails',
      JSON.stringify(
        rooms?.map((room: any) => {
          return {
            room: {
              ...room,
              quantity: 1,
              roomCount: 1,
            },
          };
        })
      )
    );
    if (rooms && rooms.length) {
      console.log('as');
      this.calcTotalPrice(rooms[0]);
    }

    this.changeNetPriceBE(constReservationData);
  }
  private convertDate(date: string): string {
    // swaping the date and return a valid date
    let dateArr = date.split('/');
    let swap1 = dateArr[0];
    let swap2 = dateArr[1];

    dateArr[0] = swap2;
    dateArr[1] = swap1;

    return dateArr.join('/');
  }
}
