<div class="container-s container">
  <div class="intro">
    <div>
      <div class="title">{{'manage_my_properties'|translate}}</div>
      <p class="subtitle">{{'manage_incoming_bookings'|translate}}</p>
    </div>
    <div>
      <button (click)="addNewProperty()" class="action-button">{{'add_neww'|translate}}</button>
    </div>
  </div>
  <app-summary-overview
    [propertiesStatistics]="propertyStastics"
  ></app-summary-overview>

  <div class="container-scroll">
    <app-reservation-management-table
      [propertyList]="propertyList"
    ></app-reservation-management-table>
  </div>

  <div class="navigation" id="navigation-print"  *ngIf="totalPages > 0">
    <mat-icon class="pointer" (click)="previous()" [ngClass]="{'btn-opacity-1':currentPage === 1}">chevron_right</mat-icon>
    <div class="active">
      <p>{{ currentPage }}</p>
    </div>
    <p  *ngIf="totalPages > currentPage">{{ currentPage + 1 }}</p>
    <mat-icon  *ngIf="totalPages > currentPage" class="pointer" (click)="next()">chevron_left</mat-icon>
  </div>
</div>
