<ng-container *ngIf="hotelDetails" style="user-select: none;">
  <div class="hotel" [dir]="isFlipped ? 'rtl' : 'ltr'">
    <div class="name" >
      <h6 class="num">{{ hotelDetails.averageRating.toFixed(1) }}</h6>
      <h6 class="num-rate">{{ hotelDetails.TotalRatings }} {{'rating'|translate}}</h6>
    </div>

    <div class="rate" >
      <h6 class="rate-name">{{!isFlipped? hotelDetails.nameAr:hotelDetails.nameEn }}</h6>
    </div>
  </div>
  <div class="nav-location" [dir]="isFlipped ? 'rtl' : 'ltr'">
    <div class="location-container">
      <h6 class="location me-2">{{ hotelDetails.city }}</h6>
      <img src="../../assets/icons/gray-pin.svg" alt=" " class="pin-icon" />
    </div>
  </div>

  <!-- <gallery [items]="imagesPreview" thumbPosition="left" gallerize [autoHeight]="false" [thumbAutosize]="true"
 thumbImageSize="contain" [counter]="false" [nav]="false" style="background-color: transparent;" imageSize="cover"></gallery> -->
  <div class="overlay hovered-pointer" *ngIf="imageGallery.selectedImage" (click)="closeImage()"></div>
  <div class="" style="user-select: none;">
    <!-- <div class="four-grid-cells">
      <img
        class="main-img"
        [src]="
          hotelDetails.images[0]
        "
        (click)="selectImage(imageGallery.images[0])"
      />
      <span class="heart-icon-container"
      *ngIf="!hotelDetails.isFavorite"
      (click)="onFavHotel(hotelDetails,true)"
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#0a0a0a" d="m12 21l-1.45-1.3q-2.525-2.275-4.175-3.925T3.75 12.812T2.388 10.4T2 8.15Q2 5.8 3.575 4.225T7.5 2.65q1.3 0 2.475.55T12 4.75q.85-1 2.025-1.55t2.475-.55q2.35 0 3.925 1.575T22 8.15q0 1.15-.387 2.25t-1.363 2.412t-2.625 2.963T13.45 19.7z"/></svg>
      </span
      >
      <span class="heart-icon-container"
      *ngIf="hotelDetails.isFavorite"
      (click)="onFavHotel(hotelDetails,false)"
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#CC0F0F" d="m12 21l-1.45-1.3q-2.525-2.275-4.175-3.925T3.75 12.812T2.388 10.4T2 8.15Q2 5.8 3.575 4.225T7.5 2.65q1.3 0 2.475.55T12 4.75q.85-1 2.025-1.55t2.475-.55q2.35 0 3.925 1.575T22 8.15q0 1.15-.387 2.25t-1.363 2.412t-2.625 2.963T13.45 19.7z"/></svg>
      </span
      >
    </div> -->
    <!-- <ng-container *ngFor="let imagePath of imageGallery.images.slice(1, 5)">
      <img
        class="grid-img"
        [src]="imagePath"
        (click)="selectImage(imagePath)"
      />
    </ng-container> -->
    <div style="height: 500px;" class="image-gallery-grid" [ngStyle]="{
      'display': 'grid',
      'border-radius': '20px',
      'grid-template-columns': 'repeat(2, 1fr)',
      'grid-template-rows': 'repeat(2, 250px)',
      'gap': '10px',
      'position': 'relative',
      'overflow': 'hidden'
    }">
  <div class="grid-item main-image" style="grid-column: span 1; grid-row: span 2; position: relative;" (click)="selectImage(imageGallery.images[0])">
    <img
      class="main-img"
      [src]="hotelDetails.mainImage"
      alt="Main Image"
      style="width: 100%; height: 100%; object-fit: cover;"
    />
    <span class="heart-icon-container" *ngIf="!hotelDetails.isFavorite" (click)="onFavHotel(hotelDetails, true, $event)" style="position: absolute; top: 10px; right: 10px; cursor: pointer; z-index: 9999999999999999;">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <path fill="#0a0a0a" d="m12 21l-1.45-1.3q-2.525-2.275-4.175-3.925T3.75 12.812T2.388 10.4T2 8.15Q2 5.8 3.575 4.225T7.5 2.65q1.3 0 2.475.55T12 4.75q.85-1 2.025-1.55t2.475-.55q2.35 0 3.925 1.575T22 8.15q0 1.15-.387 2.25t-1.363 2.412t-2.625 2.963T13.45 19.7z"/>
      </svg>
    </span>
    <span class="heart-icon-container" *ngIf="hotelDetails.isFavorite" (click)="onFavHotel(hotelDetails, false, $event)" style="position: absolute; top: 10px; right: 10px; cursor: pointer;z-index: 9999999999999999;">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <path fill="#CC0F0F" d="m12 21l-1.45-1.3q-2.525-2.275-4.175-3.925T3.75 12.812T2.388 10.4T2 8.15Q2 5.8 3.575 4.225T7.5 2.65q1.3 0 2.475.55T12 4.75q.85-1 2.025-1.55t2.475-.55q2.35 0 3.925 1.575T22 8.15q0 1.15-.387 2.25t-1.363 2.412t-2.625 2.963T13.45 19.7z"/>
      </svg>
    </span>
  </div>
  <div class="grid-item" style="grid-column: span 1; grid-row: span 2; display: grid; grid-template-columns: repeat(2, 1fr); grid-template-rows: repeat(2, 250px); gap: 10px;">
    <div *ngFor="let imagePath of imageGallery.images.slice(0, 5)" (click)="selectImage(imagePath)" style="position: relative; grid-column: span 1; grid-row: span 1;">
      <img
        class="grid-img"
        [src]="imagePath"
        alt="Gallery Image"
        style="width: 100%; height: 100%; object-fit: cover;"
      />
    </div>
  </div>
  <div  
    class="d-flex view-all align-items-center justify-content-between gap-2"
    (click)="selectImage(imageGallery.images[0])" 
    [ngStyle]="{
      'position': 'absolute',
      'bottom': '10px',
      'left': isFlipped ? '10px !important' : '0px',
      'right': isFlipped ? '0px' : '10px !important',
      'cursor': 'pointer',
      'background': 'rgba(255, 255, 255, 1)',
      'padding': '5px',
      'width': 'fit-content',
      'border-radius': '8px !important'
    }"
  >
    <div class="d-flex align-items-center w justify-content-between gap-2 " >
      <img src="../../assets/show-all-photos.svg" alt="show-all-photos" style="width: 10px;">
      <p style="min-width: 90px; " class="mb-0">{{'view_all_photos' | translate}}</p>
    </div>
  </div>
</div>
    <div *ngIf="imageGallery.selectedImage" class="large-image-form">
      <div class="image-container">
        <img
          [src]="
            imageGallery.selectedImage
          "
        />
        <svg
        (click)="onMoveNextImagePreview(imageGallery.selectedImage)"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        class="right hovered-pointer"
        viewBox="0 0 24 24"
      >
        <path
          fill="#ffffff"
          d="m14 18l-1.4-1.45L16.15 13H4v-2h12.15L12.6 7.45L14 6l6 6z"
        />
      </svg>
      <svg
        (click)="onMovePreveImagePreview(imageGallery.selectedImage)"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        class="left hovered-pointer"
        viewBox="0 0 24 24"
      >
        <path
          fill="#ffffff"
          d="m10 18l-6-6l6-6l1.4 1.45L7.85 11H20v2H7.85l3.55 3.55z"
        />
      </svg>
      </div>
      <mat-icon
        class="text-white hovered-pointer"
        (click)="closeImage()"
        mat-button
        mat-dialog-close
        >close</mat-icon
      >
      <!-- <button >arrow right</button> -->

      <!-- <button >arrow left</button> -->
      <div
        class="d-flex align-sm-items-center justify-content-sm-center w-100 gap-2 overflow-auto my-4"
      >
        <div *ngFor="let img of imageGallery.images">
          <img
            (click)="selectImage(img)"
            [src]="img"
            class="hovered-pointer"
            style="object-fit: contain; width: 100px; height: 100px"
          />
        </div>
      </div>
    </div>
  </div>
</ng-container>
