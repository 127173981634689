<div class="navigation-arrow" dir="auto" style="text-align: start;">
<app-navigation [back]="'/profile/all-orders'"></app-navigation>
</div>
<div class="content"  style="text-align: start;">

  <div class="right"  style="text-align: start;">
    <div class="shalet">
      <div class="shalet-img">
        <img [src]="kamalPayment.reservation.Property.mainImage">
      </div>
      <div class="shalet-block">
        <h6 class="shalet-name">{{!isFlipped? kamalPayment.reservation.Property.nameAr:kamalPayment.reservation.Property.nameEn }}</h6>

        <h6 class="date-from-to">{{ kamalPayment.reservation.startDate.toString()| arabicDate:false }} - {{
          kamalPayment.reservation.endDate.toString() | arabicDate:true }}</h6>
 
        <h6 class="status" [ngStyle]="{
          color: kamalPayment.reservation.reservationStatus === 'pendingPayment' ? 'green' : kamalPayment.reservation.reservationStatus === 'paid' ? 'green' : 'red'
        }">{{  (kamalPayment.reservation.reservationStatus|translate)   }}</h6>
      <!-- <div *ngIf="kamalPayment.reservation.paymentStatus == 'pending'" class="complete-payment">
        <button class="custom-button" (click)="completePayment()">{{'complete_payment_and_confirm_booking'|translate}}</button>
      </div>   -->
    </div>
    
    </div>
    <h6 class="black-st remove-margin">{{'booking_date'|translate}}</h6>
    <h6 class="violet-st">{{ kamalPayment.reservation.createdAt.toString() | arabicDate:false }}</h6>
    <!-- <h6 class="black-st space">{{'selected_rooms'|translate}}</h6>
    <div class="shalet-room" *ngFor="let room of kamalPayment.reservation.ReservationRooms">

      <div class="room-details">
        <h6 class="room-details-label">{{ ReservationDetails.roomType }}</h6>
        <div class="details-loop">
        <ng-container *ngFor="let detail of ReservationDetails.roomDetail; let i = index">
          <h6 class="one-line" >

             <p class="details-font">{{ detail.roomDetailss }}</p>
             <p class="details-quantity">{{detail.quantity}}</p>
              <img class="detail-img" [src]="detail.img" alt="">
            </h6>
        </ng-container>
      </div>
      </div>

    </div> -->
    <h6 class="black-st">{{'guest_name'|translate}}</h6>
    <h6 class="violet-st">{{kamalPayment.reservation.firstName + ' ' + kamalPayment.reservation.lastName}}</h6>
    <h6 class="black-st"> {{'mobile_number'|translate}}</h6>
    <h6 class="violet-st">{{kamalPayment.reservation.mobile}}</h6>
    <h6 class="black-st">{{'destination'|translate}}</h6>
    <h6 class="violet-st">{{kamalPayment.reservation.Property.nameAr + ' , ' + kamalPayment.reservation.Property.city}}</h6>
        <div class="flex-lg-rg">
          <h6 class="black-st">{{'check_in'|translate}}</h6>
      <h6 class="black-st">{{'check_out'|translate}}</h6>
    </div>
    <div class="flex-date">
      <h6 class="violet-st"> {{'from'|translate}} {{kamalPayment.reservation.startDate.toString() | arabicDate}}</h6>
      <h6 class="violet-st"> {{'to'|translate}} {{kamalPayment.reservation.endDate.toString() | arabicDate}}</h6>
    </div>
    <h6 class="black-st">{{'guest'|translate}}</h6>
    <h6 class="violet-st">{{('adults'|translate)+':'  + kamalPayment.reservation.adults + ' , ' + ('children'|translate)+':' + kamalPayment.reservation.childerns}}</h6>
    <h6 class="black-st map-title" (click)="onMapTitleClick()">{{ReservationDetails.mapTitle|translate}}</h6>
    <div class="map-image">
      <!-- <img src="{{ReservationDetails.mapImage}}"> -->
      <google-map   (mapClick)="onMapClick($event)" height="215px" width="100%" [options]="options">
        <map-advanced-marker [position]="markerPosition"></map-advanced-marker>
      </google-map>
    </div>
  </div>
  <div class="left ms-5 ps-5"  style="text-align: start;">
    <h6 class="sm">{{'price_details'|translate}}</h6>
    <div class="vacation">
      <div class="vacation-price">
        <h6 class="lg"> {{calcNights(kamalPayment.reservation.startDate,kamalPayment.reservation.endDate)}} {{'night'|translate}} </h6>
        <h6 class="lg">X</h6>
        <h6 class="lg">{{kamalPayment.reservation.priceAfterDiscount/(calcNights(kamalPayment.reservation.startDate,kamalPayment.reservation.endDate)||1)}}</h6>
      </div>
      <h6 class="lg">{{kamalPayment.reservation.priceAfterDiscount}}</h6>
    </div>



    <div class="discount" *ngIf="kamalPayment.reservation.priceAfterDiscount !== kamalPayment.reservation.netPrice" >
      <h6 class="md">{{'discount'|translate}}</h6>
      <h6 class="lg">{{kamalPayment.reservation.priceAfterDiscount}}</h6>
    </div>

    <div class="coupon" *ngIf="kamalPayment.reservation.copoun">
      <div class="coupon-num">
        <h6 class="md new-space">{{'discount_coupon'|translate}}</h6>
        <h6 class="md">{{kamalPayment.reservation.copoun}}</h6>

      </div>
      <h6 class="lg">{{kamalPayment.reservation.copoun}}</h6>
    </div>
    <div class="total">

      <h6 class="v-md">{{'total_amount'|translate}}</h6>
      <h6 class="v-lg">{{kamalPayment.reservation.netPrice}}</h6>
    </div>

    <div class="pay-method" *ngIf="kamalPayment.reservation.paymentMethod">
      <h6 class="sm">{{'payment_method'|translate}}</h6>
      <h6 class="v-sm">{{ kamalPayment.reservation.paymentMethod.split(' ')[0] }}</h6>
    </div>




    <!-- <div class="barcode">
      <img [src]="kamalPayment.reservation.Property.mainImage">
      <img src="assets/aroovia-icons/frame.svg" alt="">
    </div> -->

    <div class="confirm">
      <h6 class="sm">{{'booking_confirmed'|translate}}</h6>
      <h6 class="v-sm">{{ kamalPayment.reservation.id }}</h6>
    </div>

    <div class="verify">
      <h6 class="sm">{{'confirmation_email'|translate}}</h6>
      <h6 class="v-sm">{{ kamalPayment.reservation.email }}</h6>
    </div>
    <h6 class="sm">{{'property_details'|translate}}</h6>
    <div class="room-info">
      <div style="display: grid;gap: 40px; grid-template-columns: repeat(2,1fr); margin-bottom: 20px;">
          <div class="property-detail">
            <i class="fas fa-door-open"></i>
            <p>{{'rooms'|translate}}: {{ kamalPayment.reservation.Property.rooms }}</p>
          </div>
          <div class="property-detail">
            <i class="fas fa-user-friends"></i>
            <p>{{'guests'|translate}}: {{ kamalPayment.reservation.Property.guests }}</p>
          </div>
          <div class="property-detail">
            <i class="fas fa-bed"></i>
            <p>{{'beds'|translate}}: {{kamalPayment.reservation.Property.beds }}</p>
          </div>
          <div class="property-detail">
            <i class="fas fa-bath"></i>
            <p>{{'bathrooms'|translate}}: {{ kamalPayment.reservation.Property.bathrooms }}</p>
          </div>
          <div class="property-detail">
            <i class="fas fa-ruler-combined"></i>
            <p>{{'totalSpace'|translate}}: {{ kamalPayment.reservation.Property.totalSpace}} {{'meters'|translate}}</p>
          </div>
        </div>
     
  </div>
  
    <!-- <div class="payment hovered-pointer d-flex">
      <img src="assets/aroovia-icons/apple-logo.svg">
      <h6>{{ ReservationDetails.onlinePayment1 }}</h6>

    </div> -->
    <!-- apple-wallet-button.component.html -->
     <!-- <div class="position">
      <button class="apple-wallet-button" (click)="onAppleWalletClick()">
        <div class="d-flex justify-content-center m-auto">
         <p class="apple-text"><span style="margin-right: 60px; font-family: Arial, sans-serif !important; font-weight: 200; font-size: 15px;">Add to</span><br>Apple Wallet</p>
          <img src="../../assets/Apple Wallet Icon Logo Vector.png" alt="Apple Wallet"  class="apple-wallet-icon">
        </div>
       </button>
        
       <button class="google-wallet-button" (click)="onAppleWalletClick()">
        <div class="d-flex justify-content-center m-auto">
         <p class="apple-text"><span style="margin-right: 73px; font-family: Arial, sans-serif !important; font-weight: 200; font-size: 15px;">Add to</span><br>Google Wallet</p>
          <img src="../../assets/google_wallet-logo_brandlogos.net_9vbqn.png" alt="Apple Wallet"  class="google-wallet-icon">
        </div>
       </button> -->
     </div>

  </div>




