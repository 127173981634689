import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { RoomsDTO } from 'app/rooms-managment/rooms-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { SharedStateService } from 'app/sharedStateService/sharedState.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, throwError } from 'rxjs';

declare var Moyasar: any;
interface CopunDTO {
  name: string;
  type: string;
  value: number;
}

@Component({
  selector: 'app-review-reservations-details',
  templateUrl: './review-reservations-details.component.html',
  styleUrls: ['./review-reservations-details.component.css'],
})
export class ReviewReservationsDetailsComponent implements OnInit {
  @Output() orderNowEmitter: EventEmitter<FormGroup> = new EventEmitter();
  @Input() isValidPaymentForm!: FormGroup;
  @BlockUI('root') rootBlocker!: NgBlockUI;
  disabled: boolean = false; // Default to false
  constructor(
    private datacontext: DataContextService,
    private datacontent: DataContextService,
    private translate: TranslateService,
    private sharedStateService: SharedStateService
  ) {}
  isFlipped = false;

  completeReserve(data: any) {}
  async bookNow() {
    if (!this.isValidPaymentForm.valid) {
      return this.datacontext.showError(
        this.translate.instant('errorr'),
        this.translate.instant('please_check_data')
      );
    }
    if (Object.keys(this.isValidPaymentForm.value).length === 0) {
      return this.datacontext.showError(
        this.translate.instant('errorr'),
        this.translate.instant('please_check_data')
      );
    }
    this.orderNowEmitter.emit(this.isValidPaymentForm);

    const reserveData = JSON.parse(localStorage.getItem('reserveData')!);
    const token = this.datacontent.getRouteParams('token');
    const reservationsData = JSON.parse(
      localStorage.getItem('reservationInfodataBody')!
    );
  }

  starCount() {
    return Array(this.property?.hotelStars)
      .fill(0)
      .map((x, i) => i);
  }

  property: any = null;
  totalPrice: any;
  roomCount: number = 0;
  room: RoomsDTO;
  nightsCount = 0;
  endDaysCount = '';
  startDateCount = '';
  reserveData: any;
  totalPrice_BE = 0;
  totalPriceAfterDisc_BE = 0;
  copunPrice_BE = 0;
  totalPriceAfterVat_BE = 0;
  netPrice_BE = 0;
  reservationDetails: any;
  BaseURL = '';

  ngOnInit(): void {
    this.sharedStateService.currentDisabledState.subscribe((state) => {
      this.disabled = state;
    });
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    this.BaseURL = window.location.protocol + '//' + window.location.host; //+this.reservationDetails.id;
    this.reservationDetails = JSON.parse(
      localStorage.getItem('reservationDetails')!
    );
    const constReservtionData = JSON.parse(
      localStorage.getItem('constReservtionData')!
    );
    console.log(constReservtionData, 'hey');
    const UserInfo = JSON.parse(localStorage.getItem('userInfo')!);
    const roomDetails = JSON.parse(localStorage.getItem('roomsDetails')!);
    console.log(roomDetails, 'roomDetails');
    if (roomDetails) {
      this.property = constReservtionData.property;
      this.room = roomDetails.room;
      this.endDaysCount = this.datacontext.convertDateAPI(
        constReservtionData.endDate
      );
      this.startDateCount = this.datacontext.convertDateAPI(
        constReservtionData.startDate
      );
      this.nightsCount = this.calcNights(
        this.startDateCount,
        this.endDaysCount
      );
      this.totalPrice = this.calcTotal(roomDetails);
      this.totalAfterTaxses(roomDetails);
      this.totalPriceAfterDiscount(roomDetails);
    }
    // const selectedRooms=roomDetails.rooms.map((room:any)=>{return {id:room.id, quantity:room.roomsCount }})
    const token = this.datacontext.getRouteParams('token');
    this.reserveData = new FormGroup({
      PropertyId: new FormControl(this.property.id),
      email: new FormControl(UserInfo.email),
      rooms: new FormControl(
        this.property.Rooms.map((room: any) => {
          return { id: room.id, quantity: 1 };
        }).filter((room: any) => room.quantity > 0)
      ),
      copoun: new FormControl(this.copun),
      startDate: new FormControl(constReservtionData.startDate),
      endDate: new FormControl(constReservtionData.endDate),
      adults: new FormControl(constReservtionData.adults),
      infants: new FormControl(constReservtionData.infants),
      childerns: new FormControl(constReservtionData.children),
      firstName: new FormControl(UserInfo.firstName),
      lastName: new FormControl(UserInfo.lastName),
      mobile: new FormControl(UserInfo.mobile),
    });
    this.datacontext
      .postData(`/kamel/cost?token=${token}`, this.reserveData.value)
      .subscribe({
        next: (response: any) => {
          let data = response.data;
          this.totalPrice_BE = data.price;
          this.roomPrices = data.price / data.nights;
          this.totalPriceAfterDisc_BE = data.priceAfterDiscount;
          this.copunPrice_BE = data.copounDiscount;
          this.totalPriceAfterVat_BE = data.priceAfterVat;
          this.netPrice_BE = data.netPrice;
          console.log(data);
          localStorage.setItem('reserveData', JSON.stringify(data));
        },
        error: (err: HttpErrorResponse) => {
          const errorMessage = this.translate.instant(err.error.message);  // Translate the error message
          this.datacontext.showError('', errorMessage);
        },
        complete: () => {},
      });
  }

  roomPrices: number = 0;
  private calcTotal(rooms: any) {
    const constReservtionData = JSON.parse(
      localStorage.getItem('constReservtionData')!
    );
    let totalPrice = 0;
    const reducePrice = rooms.reduce((acc: any, prevRoom: any) => {
      totalPrice += prevRoom.room.price * prevRoom.room.roomCount;
      return acc;
    }, {});
    let total: number = 0;
    if (constReservtionData) {
      this.roomPrices = totalPrice;
      total = totalPrice * this.nightsCount;
    }
    return total;
  }
  private calcNights(fDate: string, lDate: string) {
    const currentDate = new Date(fDate);
    const lastDate = new Date(lDate);
    const diffInTime = Math.abs(lastDate.getTime() - currentDate.getTime());
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24)); // Convert milliseconds to days
    return diffInDays;
  }

  totalAfterTax: number = 0;
  totalAfterTaxses(r: any) {
    // const total = this.calcTotal(r)
    const totalAfterTax = (this.totalPrice * 15) / 100;

    this.totalAfterTax = totalAfterTax;
  }

  totalPriceAfterDisc = 0;
  totalPriceAfterDiscount(rooms: any) {
    let totalPrice = 0;
    const reducePrice = rooms.reduce((acc: any, prevRoom: any) => {
      totalPrice += prevRoom.room.priceAfterDiscount;

      acc['totalPrice'] = totalPrice;
      return acc;
    }, {});
    let total = reducePrice['totalPrice'];

    this.totalPriceAfterDisc = total;
    // if(total === this.totalPrice) return ;
  }

  copun: string = '';
  checkCopun() {
    if (!this.copun)
      return this.datacontext.showError('', this.translate.instant('enterCoupon'));
    const token = this.datacontext.getRouteParams('token');
    // this.rootBlocker.start()
    this.datacontext
      .getData(`/copoun/check/${this.copun}?token=${token}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop()
            this.datacontext.showError('', this.translate.instant('invalidCoupon'));
          });
        })
      )
      .subscribe((value: any) => {
        if (value.success) {
          this.calcCopounDiscount(value.data);
          this.reserveData.patchValue({ copoun: value.data });
        }
        // this.rootBlocker.stop()
      });
  }

  copunPrice: number = 0;
  private calcCopounDiscount(copunDetail: CopunDTO) {
    if (copunDetail.type === 'flat') {
      //percentage
      const priceToDisc = (this.totalPrice * copunDetail.value) / 100;
      this.copunPrice = priceToDisc;
      this.datacontext.showSuccess('', this.translate.instant('couponAddedSuccessfully'));
    }
  }
}
