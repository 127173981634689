<div class="cont">
  <div class="hotel-details">
    <div class="img-cont">
      <img [src]="property.logo" alt="" class="hotel-image" />
    </div>
    <div class="border-cust">
      <p class="section-title">{{'category'|translate}}</p>
      <p class="section">
        {{!isFlipped? property.PropertyType.nameAr:property.PropertyType.nameEn }}
      </p>
    </div>
  </div>
  <div class="hotel-features">
    <div class="padding">
      <p class="stars-title text-align-start"  >{{'hotel_stars'| translate}}</p>

      <div class="stars d-flex">
        <img
          src="../../assets/icons/stars.svg"
          alt=""
          *ngFor="let start of starCount()"
        />
      </div>
      <p class="advantages-title text-align-start"  >{{'features'|translate}}</p>
      <div style="display: flex; column-gap: 20px; flex-wrap: wrap; justify-items: center; ">
        <span class="advatages-item"
        *ngFor="let amenity of property.PropertyAmenities"
        >
        <img
        class="location"
        [src]="amenity.Amenity.image"
        alt=""
        style="width: 25px;"
        />
        <span class="advantages-text">{{!isFlipped? amenity.Amenity.nameAr:amenity.Amenity.nameEn}}</span>
        </span
        >
      </div>

      <div class="facilities">
        <p  class="facilities-title text-align-start">{{'special_facilities'|translate}}</p>
      <div class="responsive-grid" style="display: grid;padding-top: 15px; gap: 30px;   grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
">

        <p class="fac-item text-align-start" style="border:1px solid gray; text-align: center; padding: 3px 0px; border-radius: 8px; height: fit-content;" *ngFor="let menitySpecial of property.PropertySpecialAmenities">{{!isFlipped?menitySpecial.Amenity.nameAr:menitySpecial.Amenity.nameEn}}</p>
      </div>
    </div>
    </div>
  </div>
</div>

<div class="pricing-details">
  <div class="price-padding">
    <p class="price-title text-align-start"  >{{'price_details'|translate}}</p>
    <div class="price-cont gap">
      <p class="price">{{roomPrices}} ({{'ASR'|translate}}) X {{nightsCount}} {{'night'|translate}}</p>
      <p class="price">{{totalPrice_BE  | number:'1.2-2'}} {{'ASR'|translate}}</p>
    </div>

    <div class="discount-cont gap" *ngIf="totalPriceAfterDisc_BE != totalPrice_BE">
      <p class="price-title text-align-start">{{'discount'|translate}}</p>
      <p class="price">{{totalPriceAfterDisc_BE|number:'1.2-2'}} {{'ASR'|translate}}</p>
    </div>
    <div class="discount-cont gap" *ngIf="copunPrice>0">
      <p class="price-title">{{'discount_coupon_value'|translate}}</p>
      <p class="price">{{copunPrice_BE | number:'1.2-2'}} {{'ASR'|translate}}</p>
    </div>
    <div class="coupon-section gap">
      <p class="coupon-title" style="text-align: start; margin-left: 0px !important;">{{'have_coupon'|translate}}</p>
      <div class="coupon-input">
        <!-- // -->

        <!-- <input type="text"> -->
        <input type="text" [(ngModel)]="copun" style="width: 100%; padding: 10px; border: none;"/>
        <button class="button" (click)="checkCopun()" style="width: 30%;">{{'add'|translate}}</button>

        <!-- // -->
      </div>
    </div>

    <div class="total-price gap">
      <p class="total-title">{{'total_amount'|translate}}</p>
      <p class="total-price-txt"> {{ netPrice_BE | number:'1.2-2'}} {{'ASR'|translate}}</p>
    </div>
    <div class="button-container justify-content-center align-items-center">
      <button
        class="book-button"
        (click)="bookNow()"
        [disabled]="disabled"
      >
        {{'book_now'|translate}}
      </button>
    </div>
  </div>
</div>

<!-- [ngClass]="{ 'btn-disabled': !isValidPaymentForm.valid }" -->

<!-- [disabled]="!isValidPaymentForm.valid" -->
