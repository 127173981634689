
<div class="hotel-details" [dir]="isFlipped ? 'rtl' : 'ltr'" style="text-align: start;">
    <div class="left">
      <div class="up">
        <div class="left w-100">
          <p class="grey-sm" style="text-align: end !important;">

          {{!isFlipped? hotelInfo?.PropertyType?.nameAr :hotelInfo?.PropertyType?.nameEn}}</p>
          <!-- <p class="black">{{ hotelDetails.categoryName }}</p> -->
        </div>
        <div *ngIf="hotelInfo?.logo" class="right">
          <!-- <p></p> -->
          <div class="img-size">
            <img  [src]="hotelInfo?.logo "><!--'https://wovenclouds.com/arrovia/api/uploads?path='+-->
          </div>

        </div>
      </div>
      <br>
      <br>
      <div class="down" dir="auto" style="text-align: start;">
        <p *ngIf="starsArray.length >= 1" class="grey thirteen-heavy" style="text-align: start;">{{'hotel_stars'|translate}}</p>
     <div *ngIf="starsArray.length >= 1" class="orientation" style="text-align: start;">
      <div  *ngFor="let star of starsArray" style="text-align: start;">
        <p><img src="../../assets/icons/stars.svg" alt=""></p>
      </div>
     </div>
        <p class="grey thirteen-heavy">{{'features'|translate}}</p>
        <div class="responsive-grid">
        <ng-container *ngFor="let feature of hotelInfo?.PropertyAmenities">
        <div class="orient"  style="text-align: start; display: flex; gap:10px ; align-items: center; ">
          <img [src]="feature.Amenity?.image" style="width: 25px;" alt="">
          <p class="features " >{{!isFlipped? feature.Amenity.nameAr:feature.Amenity.nameEn }}</p>
          
        </div>
        </ng-container>
      </div>

        <p class="grey special-facilites">{{'special_facilities'|translate}}</p>
        <div class="responsive-grid">

          <ng-container *ngFor="let fac of hotelInfo?.PropertySpecialAmenities">
            <p  style="border:1px solid gray; text-align: center; padding: 3px 0px; border-radius: 8px; height: fit-content;" class="features" >{{!isFlipped?fac.Amenity.nameAr:fac.Amenity.nameEn}}</p>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="right">
      <p class="description l-h-1" style=" hyphens: auto;line-break: auto;word-break: break-all; word-wrap: break-word;overflow-wrap: break-word;" [dir]="isFlipped ? 'ltr': 'rtl'">{{!isFlipped? hotelInfo?.discAr:hotelInfo?.discEn }}</p>
    </div>
  </div>
